@import "../../App.scss";

.affaireId {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px);
  width: 100%;


  .containerAffaireId {
    background-color: var(--bg1);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .topcontainerAffaireId {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      .custom-tabs {
        display: flex;
        position: relative; // Maintient ce conteneur comme référence pour le positionnement absolu

        .tab, .more-options {
          margin-right: 10px;
          padding: 5px 10px;
          cursor: pointer;
          color: var(--c1);
          display: inline-flex; // S'assure que les éléments sont alignés correctement
          align-items: center;

          &:hover {
            background-color: var(--bg2); // Effet de survol
          }
          &.active {
            border-bottom: 2px solid var(--c1);
            background-color: var(--bg2);
          }
        }

        .more-options-menu{
          display: none;
          position: absolute;
          left: 355px; // Ajuste si nécessaire pour aligner sous les "..."
          top: 100%; // Place le menu sous le bouton "..."
          background-color:var(--bg2);
          box-shadow: 0 8px 16px rgba(0,0,0,0.2);
          padding: 12px 16px;
          z-index: 100;

          .tab {
            display: block; // Les options dans le menu sont disposées verticalement

            &:hover {
              background-color: var(--bg1); // Un effet de survol différent pour les options de menu
            }
          }
        }

        .show {
          display: block;
          width: 200px;
        }
      }

      .titleAffairePlanningId {
        flex-grow: 1; // Permet au titre de remplir l'espace restant
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--c1);
        padding-top: 10px;
        .btn-group-titleAffairePlanningId{

          .btn-btn-primary {
            background-color: var(--bg2);
            color: var(--c1);
            border: none;
            border-radius: 5px;
            padding: 5px 10px;
            margin-right: 10px;
            cursor: pointer;
            transition: background-color 0.3s ease;
            &:hover {
              transform: scale(1.05);
            }
          }
        }
        
        .select-affaire {
          background-color: var(--bg1); /* Couleur de fond */
          color: var(--c1); /* Couleur du texte */
          border: 2px solid var(--bg2); /* Bordure avec la couleur bg2 */
          border-radius: 5px;
          padding: 5px 10px;
          margin-right: 10px;
          cursor: pointer;
          transition: background-color 0.3s ease;
          font-size: 17px;
          width: 450px;
          -webkit-appearance: none; /* Désactiver le style par défaut pour Safari */
          -moz-appearance: none; /* Désactiver le style par défaut pour Firefox */
          appearance: none; /* Désactiver le style par défaut */
          position: relative; /* Pour positionner absolument la flèche personnalisée */
        }
        

        
        /* Style pour focus */
        .select-affaire:focus {
          outline: none;
          border-color: var(--bg2);
          background-color: var(--bg1);
        }
        
        /* Ajouter un wrapper pour la gestion de l'apparence sous Chrome, Firefox, et IE */
        .select-wrapper {
          position: relative;
          display: inline-block;
          width: auto; /* ou une largeur spécifique si nécessaire */
        }
        .select-wrapper::after {
          content: '▼'; /* Flèche vers le bas */
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translateY(-50%);
          pointer-events: none; /* Ignore les événements de la souris */
          color: var(--c1);
        }
      }
    }

    .test {
      flex-grow: 1;
      overflow-y: auto;
    }
  }
    // Style pour le container du formulaire
    .form-container {
      position: fixed;
      right: 0;
      top: 0;
      background-color: white;
      border-radius: 10px 10px 0 0;
      max-width: 100vw; 
      max-height: 100vh;
      z-index: 1000;
      overflow: hidden;
    
      .form-header {
        cursor: move;
        background: linear-gradient(to right, var(--bg2), var(--bg1-light));
        border: solid 3px var(--bg1);
        border-radius: 10px 10px 0 0;
        color: var(--c1);
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    
        .close-button {
          border: none;
          border-radius: 5px;
          background-color: var(--bg1);
          color: var(--c1);
          font-size: 20px;
          cursor: pointer;
          transition: background-color 0.3s ease;
          padding: 5px 10px;
          align-items: center;
          &:hover {
            transform: scale(1.05); // Effet de zoom au survol
          }
        }
      }
    
      .form-body {
        overflow-y: auto;
        max-width: 100vw; 
        max-height: 100vh;
        
      }
    }
}
