@import "../../App.scss";

.affaires {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px);
  width: 100%;
  overflow-y: hidden;

  .container-affaires {
    display: flex;
    flex-direction: column;
    height: 100%;

    .header-affaires {
      padding: 10px;
      background-color: var(--bg1);
      display: flex;
      align-items: center;
      color: var(--c1);

      .btn-header-affaires{
        color: var(--bg1);
        margin-left: 10px;
        background-color: var(--c2);
        color: var(--bg1);
        border: none;
        padding: 10px 10px;
        border-radius: 5px;
        cursor: pointer;
      }
      input {
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 16px;
        width: 300px;
  
        &:focus {
          border-color: #80bdff;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
      }
      span{
        margin-left: 100px;
        margin-right: 15px;
      }
    }

    .containerAffaires {
      display: flex;

      background-color: var(--bg1);
      height: 100%;
      width: 100%;

      .leftcontainerAffaires {
        flex: 5;
        display: flex;
        flex-direction: column;

        .headercontainerAffaires {
          display: flex;
          justify-content: space-around;
          background-color: var(--c2);
          padding: 10px;
          position: sticky;
          top: 0;

          h3 {
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        .listcontainerAffaires {
          overflow-y: auto;
          align-items: center;
          padding-bottom: 30px;

          .statusSection {
            margin: 20px 0;

            .status-section-h2 {
              margin-bottom: 15px;
              text-align: center;
              background-color: var(--bg2);
              padding: 10px;
            }

            .affaire-table {
              width: 100%; 
              flex-direction: column;
              padding-left: 20px;
        
              tr:nth-child(odd) {
                background-color: var(--bg1);
              }
        
              tr:nth-child(even) {
                background-color: var(--bg2);
              }
        
              td {
                text-align: left; 
              }
            }
          }
          
        }
      }

      .right {
        flex: 2;
        background-color: #f2f2f2; // Ajustez la couleur selon le besoin
      }
    }
  }
}
