@import "../../App.scss";

.timesheet {
    display: flex;
    flex-direction: column;
    height: 100%; 
    width: 100%; 
    overflow: hidden;

    .tableau-time-user {
      flex-grow: 1; // Permet à la table de prendre tout l'espace disponible
      overflow: hidden; 
      
      .table-time-user {
        max-height: 100px;
        width: 100%; // Largeur complète
        border-collapse: collapse; // Élimine les espaces entre les cellules
  
        .thead-time-user{
            display: flex;
            width: 100%;
            //top: 0;
            height: 100%;
            border: 1px solid var(--c1);
            padding: 8px;
            color: var(--c1);
           
            .tr-time-user{
        
                width: 100%;
                display: flex;
                justify-content: space-evenly;
                .th-time-user{
                text-align: left;
                }
            }
        }
        .tbody-time-user {
            display: block;
            max-height: calc(100vh - 350px); // Hauteur maximale pour le défilement
            overflow-y: auto;
            width: 100%;
          
          .td-time-user {
            display: flex;
            width: 100%;
            height: 100%;
            border: 1px solid var(--c1);
            padding: 8px;
            text-align: left;
            color: var(--c1);
            background-color: var(--bg2);
          }
        }
        .tr-result-time-user td, .tr-result-time-user th {
            padding: 15px;
            width: 100%;
          }
          
        .tr-result-time-user {
            display: flex;
            height: 100%;
            width: 100%;
            color: var(--c1);
            &:nth-child(odd) { // Pour les lignes impaires
                background-color: var(--bg1);
              }
            
              &:nth-child(even) { // Pour les lignes paires
                background-color: var(--bg2);
              }
          &:hover {
            background-color: var(--c2); // Couleur de fond au survol
            color: var(--bg1);
          }
        }
      }
    }
  
    .top-contain-timesheet{
      display: flex;
      flex-direction: column;
      .title-time-user{
        margin-left: 10px;
        margin-bottom: 20px;
        color: var(--c1);
      }

    
      .contain-timesheet{
        display: flex;
        .left-contain-timesheet{
          span{
            margin-bottom: 20px;
            margin-left: 10px;
            height: 20px;
            color: var(--c1);
          }
          .select-choix-timesheet{
            margin: 20px;
            select {
              padding: 10px 20px;
              border: none;
              background-color: var(--bg2);
              color: var(--c1);
              border-radius: 5px;
              cursor: pointer;
              &:hover {
                background-color: var(--c2); // Couleur de fond au survol
                color: var(--bg1);
              }
            }
          }
          .date-time-user{
            display: flex;
            margin: 10px;
            input {
              padding: 10px 20px;
              margin-left: 10px;
              border: none;
              border-radius: 5px;
              cursor: pointer;
              &:hover {
                background-color: var(--c2); // Couleur de fond au survol
                color: var(--bg1);
              }
            }
          }
          .select-custom-mois-timesheet{
            margin: 10px;
            
            select {
              padding: 10px 20px;
              margin-left: 10px;
              border: none;
              border-radius: 5px;
              cursor: pointer;
              &:hover {
                background-color: var(--c2); // Couleur de fond au survol
                color: var(--bg1);
              }
            }
          }
          .select-custom-semaine-timesheet{
            margin: 10px;
            input {
              padding: 10px 20px;
              margin-left: 10px;
              border: none;
              border-radius: 5px;
              cursor: pointer;
            }
            select {
              padding: 10px 20px;
              margin-left: 10px;
              border: none;
              border-radius: 5px;
              cursor: pointer;
              &:hover {
                background-color: var(--c2); // Couleur de fond au survol
                color: var(--bg1);
              }
            }
          }
        }

        .right-contain-timesheet{
          display: flex;
          margin-left: 10px;
          align-items: center;
          .btn-time-user {
            margin-left: 10px;
            padding: 10px 20px;
            margin-bottom: 20px;
            border: none;
            background-color: var(--bg2);
            color: var(--c1);
            border-radius: 5px;
            cursor: pointer;
            &:hover {
              background-color: var(--c2); // Couleur de fond au survol
              color: var(--bg1);
            }
          }
        }
      }

    }


  }