.affaire-form {
  display: flex;
  flex-direction: column;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;

  .principal-affaire-form,
  .adress-affaire-form,
  .contact-sur-place-affaire-form,
  .date-affaire-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    span {
      font-weight: bold;
      margin-bottom: 5px;
    }

    input[type="text"],
    input[type="number"],
    input[type="tel"],
    input[type="email"],
    select {
      padding: 8px;
      margin-bottom: 10px;
      border: 1px solid #ddd;
      border-radius: 4px;

      &:focus {
        border-color: #0056b3;
        outline: none;
      }
    }

    input[type="file"] {
      margin-top: 10px;
    }
  }

  button {
    padding: 10px 15px;
    border: none;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #0056b3;
    }
  }
}
