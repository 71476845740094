@import "../../../App.scss";

.listeAFaire {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: var(--bg2);
  height: 100%;
  overflow-y: hidden;
  box-sizing: border-box;

  .form-laf {
    margin-bottom: 20px;
    width: 100%;

    h2 {
      text-align: center;
      margin-bottom: 15px;
      color: var(--c1);
    }

    form {
      display: grid;
      grid-gap: 10px;

      input[type="text"],
      input[type="number"] {
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 16px;
      }

      button {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        background-color: var(--bg1);
        color: var(--c1);
        font-size: medium;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          transform: scale(1.05); // Effet de zoom au survol
        }
      }
    }
  }

  .contain-laf {
    width: 100%;
    max-width: 600px;
    overflow-y: auto;
    margin-bottom: 50px;

    ul {
      list-style: none;
      padding: 0;

      li {
        background-color: #f9f9f9;
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 5px;

        .ligne-complete-listeafaire {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .haut-ligne-listeafaire {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-grow: 1;
            width: 100%;

            .contain-li-laf {
              flex-grow: 1; // Permet au nom de prendre l'espace disponible
              white-space: nowrap; // Empêche le texte de passer à la ligne
              overflow: hidden; // Cache le texte qui dépasse du conteneur
              text-overflow: ellipsis; // Ajoute des ellipses pour le texte caché
            }

            .reminder {
              font-size: 10px;
            }

            .btn-li-lap {
              display: flex;
              justify-content: flex-end;

              button {
                padding: 5px 10px;
                margin-left: 10px;
                color: white;
                border: none;
                border-radius: 3px;
                cursor: pointer;

                &.info {
                  background-color: #2865a7;

                  &:hover {
                    background-color: darken(#2865a7, 5%);
                  }
                }

                &.green {
                  background-color: #28a745;

                  &:hover {
                    background-color: darken(#28a745, 5%);
                  }
                }


                &.red {
                  background-color: #dc3545;

                  &:hover {
                    background-color: darken(#dc3545, 5%);
                  }
                }
              }
            }
          }

          .etat-non-commence {
            color: red;
          }

          .etat-en-cours {
            color: orange;
          }

          .etat-termine {
            color: green;
          }
        }
      }
    }

    p {
      text-align: center;
    }
  }
}