@import "../../../App.scss";

.AssignationTacheId{
display: flex;

.leftAssignationTacheId{
flex: 1;
}
.rightAssignationTacheId{
    flex: 1
    ;
}
}