@import "../../../App.scss";

.elementId{


form {
    display: flex;
    flex-direction: column;
    gap: 20px; // Espacement entre les éléments du formulaire
    max-width: 500px; // Largeur maximale du formulaire
    margin: auto; // Centrer le formulaire horizontalement
  
    input[type="text"],
    input[type="number"],
    textarea,
    select {
      padding: 10px;
      border: 1px solid #ccc; // Bordure légère pour les champs
      border-radius: 5px; // Bordures arrondies pour une esthétique moderne
      font-size: 16px; // Taille de police pour une bonne lisibilité
  
      &:focus {
        border-color: #007bff; // Couleur de bordure au focus
        outline: none;
        box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25); // Ombre externe pour un effet de focus
      }
    }
  
    textarea {
      height: 120px; // Hauteur fixe pour textarea
      resize: vertical; // Permet à l'utilisateur de redimensionner verticalement
    }
  
    select {
      appearance: none; // Supprimer l'apparence native
      background-color: #fff;
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" fill="%23007bff"><polygon points="249.6,336 774.4,336 512,670.4"/></svg>'); // Icône de flèche personnalisée
      background-repeat: no-repeat;
      background-position: right 10px center; // Position de l'icône de flèche
      background-size: 12px; // Taille de l'icône de flèche
    }
  
    button[type="submit"] {
      padding: 10px 15px;
      background-color: #007bff; // Couleur de fond pour le bouton
      color: #fff; // Couleur de texte pour le bouton
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase; // Majuscules pour le texte du bouton
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #0056b3; // Assombrir le bouton au survol
      }
    }
  }
}