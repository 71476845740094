.TacheAndDecalage {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
    label {
      font-size: 16px;
      color: #495057;
      margin-bottom: 10px;
    }
  
    input[type="number"] {
      width: 30%;
      padding: 8px 12px;
      border: 2px solid #ced4da;
      border-radius: 4px;
      font-size: 16px;
      color: #495057;
  
      &:focus {
        border-color: #80bdff;
        outline: none;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }
    }
  
    button {
      padding: 10px 15px;
      margin-top: 20px;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.2s;
  
      &:hover {
        background-color: #0056b3;
      }
    }
  }
  