@import "../../../App.scss";

.AbsentDay-contain{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  
    .NbrRapportAbsentDay {
      background-color: #ffffff;
      border-radius: 15px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      padding: 20px;
      text-align: center;
      transition: transform 0.3s ease;
  
      &:hover {
        transform: scale(1.05); // Effet de zoom au survol
      }
  
      .title-abs-day {
        margin-bottom: 10px;
        font-size: 18px;
        color: #333333;
      }
  
      .result-abs-day {
        background-color: #4caf50; // Couleur verte
        color: #ffffff;
        border-radius: 50%; // Rendre le div circulaire
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-weight: bold;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        animation: pulse 2s infinite;
  
        p {
          margin: 0;
        }
      }
    }
  }