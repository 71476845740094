.form {
  padding-top: 0px !important;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .dropzone {
    cursor: pointer;
    border-style: dashed;
    border-width: 1px;
    border-color: #c4c4c4;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding: 10px;

    &.success {
      border-color: #2e7d32;
      color: #2e7d32;
    }

    &:hover {
      border-color: #2f4b52;
    }

    img {
      height: 50px;
      width: 50px;
    }

    .preview {
      display: flex;
      gap: 4px;
      align-items: center;
    }
  }

  .info {
    display: flex;
    gap: 4px;
    align-items: center;
  }
}