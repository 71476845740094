@import "../../App.scss";

.affaire-row {
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 100%;
  &:hover {
      background-color: #f5f5f5; // Couleur de survol légère pour indiquer l'interactivité
  }
}


.img-affaire-row {
  text-align: center; // Centrer l'image dans la cellule
  padding-right: 15px;
  .img-icon-affaire-row {
    padding-top: 10px;
    padding-right: 25px;
    padding-left: 45px;
    width: 30px; // Taille de l'icône
    height: 30px; // Taille de l'icône
    color: var(--c1); // Couleur de l'icône
  }
}

.detail-affaire-row {
  width: 300px; // Largeur fixe pour les cellules de détail
  color: var(--c1); // Couleur du texte plus foncée pour un contraste amélioré
  align-items: center;
}

.btn-affaire-row {
  height: 50px;
  text-align: right;
  width: auto; // Permet à la cellule de grandir pour occuper l'espace restant si nécessaire
  white-space: nowrap; // Empêche le contenu de passer à la ligne suivante
  /* Assure que le contenu de .btn-affaire-row est poussé à droite */
}

.btn-container {
  display: flex;
  justify-content: flex-end; // Alignement du contenu à droite
  width: 100%; // Utilise toute la largeur de la cellule
  button {
    background-color: var(--c2);
    color: var(--bg1);
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: var(--c2); // Assombrir le bouton lors du survol pour un retour visuel
    }

    &:focus {
      outline: none; // Supprimer l'outline par défaut pour les boutons au focus
    }
  }
}
