@import "../../../App.scss";

.GraphAffaireRapportNbrUtilisateur {
  background: linear-gradient(to right, var(--bg2), var(--bg1-light));
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 15px;
  padding: 15px;
  color: var(--c1);

  .title-garnu {
    text-align: center;
    margin-bottom: 20px;

    h2 {
      margin: 0;
    }
  }

  .react-chart {
    // Ajoutez des styles spécifiques à votre chart ici si nécessaire
  }

  .bar.nombreDePersonnes,
  .nombreDePersonnes {
    fill: #8884d8; // Couleur pour 'Nombre de Personnes'
    color: #8884d8;
  }

  .bar.progression,
  .progression {
    fill: #82ca9d; // Couleur pour 'Progression (%)'
    color: #82ca9d;
  }

  .scatter,
  .scatter {
    fill: red; // Couleur pour 'Point de Comparaison'
    color: red;
  }

  .area.mainOeuvre,
  .mainOeuvre {
    fill: #cba2807c; // Couleur pour 'Pourcentage Main-d'Œuvre (%)'
    stroke: #cba2807c;
    color: #cba2807c;
  }

  .custom-legend {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;

    li {
      display: inline-block;
      margin-right: 15px;
      font-size: 0.8rem;
    }
  }

  .custom-tooltip {
    background-color: var(--bg1);
    border-radius: 10px;
    padding: 5px;

    p {
      margin: 0;
    }
  }
  .axis{
 // Couleur pour 'Pourcentage Main-d'Œuvre (%)'
    stroke:var(--bg1);
    .recharts-cartesian-axis-tick {
        fill: var(--bg1); // Pour le texte des ticks
      }
  
      .recharts-cartesian-axis-line {
        stroke: var(--bg1); // Pour la ligne de l'axe
      }
      .recharts-cartesian-axis-tick text {
        fill: var(--bg1); // Pour le texte des ticks
      }
  }
}
