@import "../../App.scss";

.usersid{
    display: flex;
    flex-direction: column;
    height:  calc(100vh - 50px);
    width: 100%;

    .containerusersId{
       display: flex;
        height: 100%;

        .userIdContainer{
            background-color: var(--bg1);
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
        
            .titleuserIdContainer{
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--c1);
                width: 100%;
               height: 30px;
               margin: 10px 0;
            }
            .custom-tabs {
                display: flex;
                justify-content: center;
                /* autres styles */
              }
        
              .tab {
                margin: 0 10px;
                padding: 10px 20px;
                cursor: pointer;
                color: var(--c1);
              }
        
              .tab.active {
                //font-weight: bold;
                border-bottom: 2px solid var(--c1);
                background-color: var(--bg2);
              }
            .titleuserIdContainer{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
               height: 30px;


            }
        }
    }
}