@import "../../../App.scss";


.tacheprogression {
    background-color: var(--bg1); // Utilise la couleur de fond du thème vert
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  
    .top-tacheprogression {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;
      width: 50%;
 
      .MuiInputBase-root, .MuiInputLabel-root { 
        color: var(--c1);
      }
    
      .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline { 
        border-color: var(--c1);
      }
    
      .MuiSelect-select.MuiSelect-select { 
        color: var(--c1);
      }
    }
    .choose-progress-tp {
      margin-bottom: 15px;
 
      h3 {
        background-color:var(--bg4);
        color: var(--c1);
        margin-bottom: 10px;
        padding: 10px;
      }
  
      .progress-btn-tp {
        margin: 5px;
        border: 2px solid var(--bg3);
        background-color: var(--bg2);
        color: var(--c1);
        transition: background-color 0.3s ease;
  
        &:hover {
          background-color: var(--bg3);
        }
  
        &.MuiButton-contained { // Assurez-vous que ce sélecteur correspond à la classe de votre bouton contenu
          background-color: var(--bg1);
          color: var(--c1);
        }
      }
  
      .progress-switch-tp {
        display: flex;
        align-items: center;
        margin-top: 10px;
  
        .progress-switch-tp-int {
          color: var(--bg4); // Couleur pour le switch
        }
  
        .MuiSwitch-track { // Assurez-vous que ce sélecteur correspond à la classe de la piste du switch
          background-color: var(--bg2);
        }
      }
    }
  }