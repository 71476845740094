@import "../../App.scss";

.ligneTacheContainer-tachespl {
  cursor: pointer;

  .barreTache-tachespl {
    display: flex;
    border-radius: 5px; // Bords arrondis pour la barre entière
    overflow: hidden; // Pour que les bords arrondis s'appliquent aux enfants aussi

    .jourTache-tachespl {
      flex-shrink: 0;
      height: 25px;
      box-sizing: border-box;
      border-top: 1px solid var(--bg1);
      border-right: 1px solid var(--bg1);
      transition: transform 0.3s ease; // Transition pour un effet de survol

      &:hover {
        transform: translateY(-1px); // Légère élévation au survol
      }

      &.highlight-tachespl {
        &:first-of-type {
          border-top-left-radius: 25px;
          border-bottom-left-radius: 5px;
        }
    
        &:last-of-type {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
        &.green { // Tâche terminée
          background-color: #4caf50;
          background-image: linear-gradient(to bottom, #4caf50, lighten(#4caf50, 5%));
          border: 1px solid darken(#4caf50, 10%); 
        }

        &.orange { // Tâche en cours
          background-color: #ff9800;
          background-image: linear-gradient(to bottom, #ff9800, lighten(#ff9800, 5%)); 
          border: 1px solid darken(#ff9800, 10%);
        }

        &.red { // Tâche non commencée
          background-color: #f44336;
          background-image: linear-gradient(to bottom, #f44336, lighten(#f44336, 5%));
          border: 1px solid darken(#f44336, 10%);
        }
      }

      &.weekend-tachespl,
      &.holiday-tachespl {
        background-color: #f5f5f5; // Fond plus clair pour les week-ends et jours fériés
      }
    }
  }
}
