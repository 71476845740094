@import "../../../App.scss";

.GraphTachesParAffaire {
    background-color: var(--bg1);
    color: var(--c1);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    margin: 20px 0;
  
    .title-gtpa {
      text-align: center;
      margin-bottom: 20px;
  
      h2 {
        margin: 0;
        color: var(--c2);
      }
    }
    .btn-suivant-prec-gtpa {
        display: flex;
        justify-content: center;
        margin-top: 15px;
      
        button {
          padding: 8px 15px;
          margin: 0 10px;
          background-color: var(--bg2); // Couleur de fond du bouton
          color: var(--c1); // Couleur du texte
          border: none;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s, color 0.3s;
      
          &:hover {
            background-color: var(--bg3); // Couleur de fond au survol
          }
      
          &:disabled {
            background-color: var(--c2); // Couleur de fond pour les boutons désactivés
            color: var(--bg1);
            cursor: not-allowed;
          }
        }
      }
    .graph-gpta {
      display: flex;
      justify-content: center;
      padding-top: 10px;
    }
  
    .custom-legend-gtpa {
        list-style-type: none;
        padding: 0;
        text-align: center;
        margin-top: 10px;
      
        li {
          display: inline-block;
          margin-right: 15px;
          font-size: 0.8rem;
          // Pas besoin de définir une couleur générale ici, car elle sera définie individuellement pour chaque classe
      
          &.duree-planif-gtpa {
            color: #8884d8; // Assorti à la couleur de la barre "Durée Planifiée"
          }
      
          &.duree-reel-gtpa {
            color: #82ca9d; // Assorti à la couleur de la barre "Durée Réelle"
          }
      
          &.pourcentage-reali-gtpa {
            color: #ffc658; // Assorti à la couleur de la barre "Pourcentage Réalisation"
          }
      
          &.point-compar-reel-gtpa {
            color: red; // Assorti à la couleur de la barre "Point de Comparaison Réel"
          }
        }
      }
    .custom-tooltip-gtpa {
      background-color: var(--c2);
      border-radius: 5px;
      padding: 10px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  
      p {
        margin: 5px 0;
        color: var(--bg1);
        &.Nom-tache-gtpa {
          font-weight: bold;
        }
        &.duree-planif-gtpa {
          color: #8884d8; // Couleur pour Durée Planifiée
        }
        &.duree-reel-gtpa {
          color: #82ca9d; // Couleur pour Durée Réelle
        }
        &.pourcentage-reali-gtpa {
          color: #ffc658; // Couleur pour Pourcentage Réalisation
        }
        &.point-compar-reel-gtpa {
          color: red; // Couleur pour Point de Comparaison Réel
        }
      }
    }
  }