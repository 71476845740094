@import "../../App.scss";

.timesheet-page {
    display: flex;
    flex-direction: column;
    margin: 20px;

  
    .contain-timesheet-page {
      display: flex;
      justify-content: space-between;
  
      .top-timesheet-page {
        flex: 2;
        margin-bottom: 20px;
        margin-right: 20px;
      }

      .left-contain-timesheet-page {
        flex: 1;
        padding: 20px;
        background-color: #f5f5f5;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
        .select-choix-timesheet-page {
          margin-top: 10px;
          select {
            width: 100%;
            padding: 8px;
            border: 1px solid #ccc;
            border-radius: 4px;
            background-color: white;
          }
        }
  
        .date-time-user {
          display: flex;
          gap: 10px;
          margin-top: 10px;
  
          .DatePicker {
            flex-grow: 1;
          }
        }
  
        .select-custom-mois-timesheet,
        .select-custom-semaine-timesheet {
          display: flex;
          gap: 10px;
          margin-top: 10px;
  
          select, input {
            flex: 1;
            padding: 8px;
            border: 1px solid #ccc;
            border-radius: 4px;
            background-color: white;
          }
        }
      }
  
      .right-contain-timesheet {
        padding: 20px;
        align-self: start;
  
        .btn-time-user {
          padding: 10px 15px;
          background-color: #4CAF50;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          margin-bottom: 10px;
          margin-left: 10px;
  
          &:hover {
            background-color: #45a049;
          }
        }
      }
    }
  
    .contain-table-timesheet-page {
      margin-top: 20px;
  
      table {
        width: 100%;
        border-collapse: collapse;
  
        th, td {
          text-align: left;
          padding: 8px;
          border-bottom: 1px solid #ddd;
        }
  
        th {
          background-color: #4CAF50;
          color: white;
        }
  
        tbody tr:hover {
          background-color: #f5f5f5;
        }
      }
    }
  
    .total-hours-display {
      margin-top: 20px;
      font-size: 16px;
      span {
        font-weight: bold;
      }
    }
  }
  