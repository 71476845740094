@import "../../App.scss";

.Planning {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px); // Ajustez en fonction de la hauteur de votre top bar ou en-tête
  width: 100%;
  background-color: var(--bg1);

  .navPlanning {
    .tabs {
      display: flex;
      position: sticky;
      top: 0;
      justify-content: center;
      align-items: center;
      background-color: #f5f5f5;
      padding: 10px 0;
      box-sizing: border-box;
      z-index: 10;
      height: 40px;

      .tab {
        padding: 0 10px;
        cursor: pointer;

        &.active {
          border-bottom: solid 1px var(--bg1);
        }
      }
    }
  }

  .partieHautePlanning {
    display: block; // Assurez-vous que cela est visible par défaut ou basé sur une condition
    overflow-y: auto;
    width: 100%;
    transition: height 0.3s ease; // Transition douce pour le changement de hauteur

    &.show {
      height: 80%; // Affiche cette partie à 80% de la hauteur lorsque les détails sont à montrer
    }

    &.hide {
      display: none; // Cache cette partie si non nécessaire
    }

    .tabContentPlanning {
      height: 100%;
      width: 100%;

      .ongletplanning3, .ongletplanning4 {
        color: var(--c1);
        width: 100%;
        text-align: center;

        h2 {
          color: var(--c1);
          padding-bottom: 5px;
        }
      }
    }
  }

  .partiebasseplanning {
    transition: height 0.3s ease; // Transition douce pour le changement de hauteur
    width: 100%;
    overflow-y: auto;

    &.fullHeight {
      height: 100%; // Utilise toute la hauteur disponible si les détails ne sont pas affichés
    }

    &.reduced {
      height: 20%; // Réduit la hauteur à 20% lorsque les détails sont affichés
    }
  }
}
