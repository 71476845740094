@import "../../App.scss";

.supertopbar-container {
  position: relative;
  padding: 10px;
  //background: linear-gradient(to right,#496a6b, lighten(#496a6b, 15%));
  //background-color: var(--bg1-light);
  background: linear-gradient(to right, var(--bg2), var(--bg1-light));
  display: flex;
  justify-content: space-between;
  align-items: center;

  .supertopbar-title {
    font-size: 30px;
    font-weight: 500;
    color: #f7f0cf;
  }

  .img-top-bar {
    width: 30px;
    height: 30px;
    padding: 10px;
    cursor: pointer;
    color: var(--c1);
    transition: background-color 0.3s; // Ajout d'une transition pour une meilleure expérience utilisateur

    &:hover {
      background-color: var(--bg1);
    }
  }
}