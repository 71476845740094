@import "../../../App.scss";

.modal-header{
    display: flex;
    justify-content: space-between;
    z-index: 10000 !important;

    h2{
        padding-left: 50px;
        padding-bottom: 30px;
    }
    button{
        width: 30px;
        height: 30px;
    }
    .modal-content{
        display: flex;
        flex-direction: column;
    }
}