@import "../../../App.scss";

.affaireRapports {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px;
  gap: 20px;

  .rapportList {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-height: 600px;
    overflow-y: auto;

    .mesaffaireRapports {
      color: var(--c1);
    }
    .rapportItem {
      cursor: pointer;
      padding: 10px;
      border: 1px solid var(--c1);
      margin-bottom: 5px;
      color: var(--c1);
      &:hover {
        background-color: var(--c2);
        color: var(--bg1);
      }
      &.selected {
        background-color:  var(--c2);
        font-weight: bold;
        color: var(--bg1);
      }
    }
  }


  .rapportDetail {
    flex: 3;
    height: 100%;
    overflow-y: auto;
    background:  var(--c2);
    color: var(--b1);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    margin: 20px 0;

    .title-rapport-detail,
    .team-rapport-detail,
    .tache-rapport-detail,
    .questions-rapport-detail,
    .commentaires-rapport-detail {
      margin-bottom: 20px;
 
      h1{
        color: var(--c1);
       background-color: var(--bg1);
       padding: 10px;
       border-radius: 10px 10px 0 0;
      }
      h2{
        color: var(--c1);
       background-color: var(--bg1);
       padding: 10px;
       border-radius: 0 0 10px 10px ;
      }
      h3,
      h4 {
        color: var(--c1);
       background-color: var(--bg1);
       padding: 10px;
       border-radius: 10px;
      }

      ul {
        list-style-type: none;
        padding: 0;

        li {
       
          color: var(--bg1);
          padding: 10px;
          margin: 5px 0;
          border-radius: 5px;
        }
      }
    }

    .commentaires-rapport-detail {
      p {
    
        color: var(--bg1);
        padding: 10px;
        border-radius: 5px;
      }
    }
  }
}
