@import "../../../../App.scss";

.DescriptionContain {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: aqua;

  .input-description-contain {
    width: calc(100% - 20px);
    height: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }
}
