@import "../../App.scss";

.superadmin-entreprises-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
  margin: 10px 15px;

  >.title {
    display: flex;
    justify-content: space-between;

    >h1 {
      font-weight: 500;

      >span {
        font-size: 18px;
        font-weight: 400;
      }
    }

  }
}