@import "../../../App.scss";

.TacheListCondencer {
  display: flex;
  flex-direction: column;
  background: linear-gradient(to right, var(--bg2), var(--bg1-light));
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 100%;
  width: 100%;
  flex-wrap: nowrap;
  .topTacheListCondencer {
    height: 50px;
    border-bottom: 1px solid var(--c1);
    display: flex;
    justify-content: space-between; 
    align-items: center; // Centrage vertical
    padding-top: 5px;
    padding-bottom: 5px;

    .searchTacheListCondencer {
        justify-content: space-between; 
        align-items: center; 
        display: flex;
      width: 300px;
      padding-left: 40px;
      .search-field-tlc {
        background-color: transparent;
        border: 1px solid var(--c1);
        color: var(--c1);
        border-radius: 4px;
        padding: 5px 10px;
      
        // Style pour le label du champ de texte
        label {
          color: var(--c1);
        }
      
        // Style pour l'entrée du champ de texte
        input {
          color: var(--c1);
          &::placeholder {
            color: var(--c1);
          }
        }
      }
      
      .form-control {
        margin: normal;
        color: var(--c1);
      
        // Style pour le label du select
        label {
          color: var(--c1);
        }
      
        // Style pour le select lui-même
        select {
          background-color: transparent;
          color: var(--c1);
          border: 1px solid var(--c1);
          border-radius: 4px;
          padding: 5px 10px;
      
          // Style pour les options du select
          option {
            background-color: black; // Pour un contraste élevé
            color: var(--c1);
          }
        }
      }
    }

 

    .TacheListCondencer {
      width: 200px;
      padding-right: 40px;
      padding-top: 5px;
      display: flex;
      flex-direction: column;
      color: var(--c1);
gap: 5px;
      select{
        background-color: transparent;
        color: var(--c1);
        border: none;
        outline: none;
        box-shadow: none;
        border: 1px solid var(--c1);
      
      }
    }
  }

  .tableContainer {
    overflow-x: auto; // Pour le défilement horizontal si nécessaire
    height: 300px;
    flex-wrap: nowrap;
    table {
      width: 100%; // Assurer que la table n'excède pas la largeur de son conteneur
      border-collapse: collapse;
      height: 30px;
      thead {
        position: sticky;
        top: 0;
        tr {
          th {
            text-align: center;
            vertical-align: middle;
            padding: 10px;
            background-color:var(--bg4);
            color: var(--c1);
          }
        }
      }

      tbody {
        overflow-y: auto;

        .nom-tache-tlc {
          max-width: 100px;
          overflow: hidden;
          line-height: 1.2em;
          max-height: 3.6em; /* Hauteur pour 3 lignes de texte, ajustez selon vos besoins */
          padding-right: 1em; /* Pour éviter que le texte et les points de suspension ne se chevauchent */
          &:after {
            content: '...';
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }
        tr {
          &:nth-child(odd) {
            background-color:var(--bg1);
          }
          &:nth-child(even) {
            background-color: var(--bg2);
          }

          td {
            text-align: center;
            vertical-align: middle;
            padding: 10px;
            color: var(--c1);
            border-bottom: 1px solid #e0e0e0;

            &:first-child {
              text-align: left;
            }
          }
        }
      }
      .btn-modif-tlc{
        padding: 10px 20px;
        border-radius: 20px;
        background-color: #4caf50; // Vert pour "Modifier"
        color: white;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.2s ease;
      
        &:hover {
          background-color: #388e3c; // Un vert plus foncé au survol
          transform: scale(1.05); // Effet de zoom légèrement
        }
      
        &:active {
          transform: scale(0.95); // Effet de pression
        }
      }
    }
  }

  @media (max-width: 768px) {
    .topTacheListCondencer {
      flex-direction: column;

      .searchTacheListCondencer,
      .selectTacheListCondencer {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }
}
