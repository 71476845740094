@import "../../../App.scss";

.BarreABoutons {
  display: flex;
  justify-content: flex-start;
  background: linear-gradient(to right, var(--bg2), var(--bg1-light));
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 5px 15px 15px 15px; /* Haut, Droite, Bas, Gauche */
padding: 5px 10px 10px 10px;
    gap: 10px;

  .btn-barre-boutons {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: var(--bg1);
    color: var(--c1);
    font-size: medium;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        transform: scale(1.05); // Effet de zoom au survol
      }
  }
}