@import "../../App.scss";

.sidebar {
  background-color: var(--bg1);
  height: 100%;
  width: 200px;
  display: flex;

  flex-direction: column;
  .active {
    background-color: var(--bg2);
  }
  .search {
    padding-top: 10px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--c1);
    .searchimg {
      color: var(--c1);
    }
    input {
      background-color: transparent;
      color: var(--c1);
      border: none;
      outline: none;
      box-shadow: none;
    }
    .custom-input::placeholder {
      color: var(--c1);
    }
  }

  .onglet {
    //width: 100%;
    height: 100%;
    background-color: var(--bg2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;
    //margin-right: 20px;
    margin-bottom: 10px;
    border-radius: 20px;
    li.active {
      background-color: var(--bg1)
    }
    ul {
      padding-left: 0px;
      list-style: none;
    }
    li {
  

      height: 50px;
      border-bottom: 1px solid var(--bg1);
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        background-color:var(--bg1);

    }

    }
  
    .img{
        height: 35px;
        width: 35px;
        color: var(--c1);
        background-color: transparent;
        padding-left: 10px;
        //box-shadow: 9px 8px 14px 2px rgba(0,0,0,0.82); 
    }
    span{
        color: var(--c1);
        padding-left: 10px;
 
    }
    .navtop {
      //height: 100%;
      padding-left: 5px;
      //background-color: var(--bg2);
      border-radius: 20px 20px 0 0;
    }
    .empty-slot {
        flex-grow: 1;
        background-color: var(--bg2);
        background-image: linear-gradient(to top, var(--bg1), var(--bg1) 1px, transparent 1px);
        background-size: 100% 50px;
    }
  }
}
