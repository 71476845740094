@import "../../../../App.scss";

.DestinataireInput{

    .input-suggestion-di {
        position: relative;
       
      }
      
      .input-tags {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 5px;
      }
      
      .tag {
        background-color: #e1e1e1;
        padding: 5px;
        border-radius: 5px;
        display: flex;
        align-items: center;
      }
      
      .input-champ-di {
        border: none;
        flex-grow: 1;
        padding: 5px;
        min-width: 200px;
      }
      
      .suggestions {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 1000;
        background: white;
       
      }
      
}