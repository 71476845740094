@import "../../../App.scss";

.TacheCreationForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;

    .top-TacheCreationForm {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;

        h2 {
            margin-bottom: 15px;
            color: #333;
        }

        .select-affaire {
            width: 50%; // Adaptez cette valeur à votre design
            padding: 8px;
            border-radius: 4px;
            border: 1px solid #ccc;
            font-size: 16px;
            margin-bottom: 20px;
        }
    }

    .formContainer-TacheCreationForm {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .TacheFormPlanning {
            width: 100%;
            height: 100%;
        }

    }
}