@import "../../App.scss";

.PlanningEntreprise {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px);
  width: 100%;
  background-color: var(--bg1);
  align-items: center;

  .month-tabs-PlanningEntreprise {
    display: flex;
    justify-content: center;
    height: 40px;

    .month-tab-PlanningEntreprise {
      padding: 10px 15px;
      margin: 0 5px;
      background-color: #f0f0f0;
      border: none;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover,
      &.active {
        background-color: #007bff;
        color: white;
      }
    }
  }

  .filters-PlanningEntreprise {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #f5f5f5; /* Un arrière-plan légèrement gris pour distinguer la section des filtres */
    border-radius: 8px; /* Arrondir les bords pour une apparence douce */
  }

  .filters-PlanningEntreprise input,
  .filters-PlanningEntreprise select {
    padding: 8px 12px;
    border: 1px solid #ccc; /* Bordure subtile */
    border-radius: 4px; /* Arrondir les bords des champs de saisie et des sélecteurs */
    font-size: 16px; /* Assurez-vous que le texte est assez grand pour être lu confortablement */
  }
  .filters-PlanningEntreprise input {
    width: 250px;
  }
  .filters-PlanningEntreprise button {
    padding: 8px 16px;
    background-color: #007bff; /* Une couleur bleue standard pour les boutons */
    color: white; /* Texte blanc pour contraste */
    border: none; /* Aucune bordure pour un bouton */
    border-radius: 4px; /* Arrondir les bords du bouton */
    cursor: pointer; /* Change le curseur pour indiquer qu'il est cliquable */
    transition: background-color 0.2s; /* Transition douce pour le survol */
  }

  .filters-PlanningEntreprise button:hover {
    background-color: #0056b3; /* Assombrir le bouton au survol pour un feedback */
  }

  /* Ajouter un peu d'espace entre les éléments de filtre */
  .filters-PlanningEntreprise > *:not(:last-child) {
    margin-right: 10px;
  }

  .title-PlanningEntreprise {
    display: flex;
    align-items: center;
    width: 95%;
    background-color: #fff;
    border-bottom: 2px solid #007bff;
    justify-content: space-between;
    .btn-ajout-tache-PlanningEntreprise {
      margin-left: 5px;
      button,
      span {
        margin-left: 5px;
      }
      button {
        padding: 5px 10px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #0056b3;
        }
      }
    }
    h2 {
      // margin-bottom: 20px;
      z-index: 10;
      padding: 10px;
    }
  }

  .month-content-PlanningEntreprise {
    width: 95%;
    height: 95%;
    overflow-y: auto;
    background-color: white;
    margin-bottom: 30px;

    .head-PlanningEntreprise {
      position: sticky;
      top: 0;
      background-color: #007bff;
      color: white;
      z-index: 2;
    }

    .table-PlanningEntreprise {
      width: 100%;
      border-collapse: collapse;

      .head-PlanningEntreprise {
        position: sticky;
        top: 0;
        background-color: #007bff;
        color: white;
        z-index: 2;

        th {
          border-top: 1px solid #007bff;
        }
      }

      // Utilisation de trois couleurs pour les jours
      .lundi,
      .jeudi {
        background-color: #e8f4fa; // Bleu très clair
      }

      .mardi,
      .vendredi {
        background-color: #fffde7; // Jaune très clair
      }

      // Une troisième couleur pour les mercredis
      .mercredi {
        background-color: #e8eaf6; // Lavande très clair
      }
      
      .dernier-jour {
        border-bottom: 3px solid #000; /* Adapté à vos besoins */
      }


      th,
      td {
        border: 1px solid #ddd;
        text-align: left;
        padding: 8px;
      }

      .tr-PlanningEntreprise:hover {
        background-color: #f0f0f0; // Un gris très clair pour le survol
      }

      td[colspan="10"] {
        text-align: center;
        font-style: italic;
      }

      .pas-de-taches {
        color: #6c757d; // Utiliser une couleur grise pour indiquer le texte inactif ou secondaire
        font-style: italic;
      }

      .button-add-task {
        background-color: #007bff;
        color: white;
        padding: 5px 10px;
        margin-top: 5px;
        border-radius: 4px;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }

    // Style pour le container du formulaire
    .form-container {
      position: fixed;
      right: 0;
      top: 0;
      background-color: white;
      border-radius: 10px 10px 0 0;
      max-width: 100vw; 
      max-height: 100vh;
      z-index: 1000;
      overflow: hidden;
    
      .form-header {
        cursor: move;
        background: linear-gradient(to right, var(--bg2), var(--bg1-light));
        border: solid 3px var(--bg1);
        border-radius: 10px 10px 0 0;
        color: var(--c1);
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    
        .close-button {
          border: none;
          border-radius: 5px;
          background-color: var(--bg1);
          color: var(--c1);
          font-size: 20px;
          cursor: pointer;
          transition: background-color 0.3s ease;
          padding: 5px 10px;
          align-items: center;
          &:hover {
            transform: scale(1.05); // Effet de zoom au survol
          }
        }
      }
    
      .form-body {
        overflow-y: auto;
        max-width: 100vw; 
        max-height: 100vh;
        
      }
    }
}
