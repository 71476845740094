@import "../../App.scss";

.planningContainer {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: var(--bg2);
  flex-wrap: nowrap;

  .leftplanningContainer {
    display: flex;
    flex-direction: column;
    width: 300px;
    border-right: 1px solid var(--bg1);
    overflow-x: auto;
    overflow-y: hidden;
    .selectleftplanningContainer {
      height: 50px;
    }
    .a {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow-x: auto;
      overflow-y: auto;
    }
  }

  .headerScroll {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: auto;

    .headerheaderScroll {
      position: sticky;
      top: 0;
      z-index: 10;
      width: max-content; 
    }
    .contentheaderScroll {
      width: max-content;
      overflow-y: auto; // Ajouté pour le défilement vertical
    }
  }
}
