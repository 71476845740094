@import "../../App.scss";

.taches {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px);
  width: 100%;
  background-color: var(--bg1);
  align-items: center;
  .containtaches{

    height: 100%;
    width: 90%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .header-taches {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
  
      h1 {
        color: var(--c1);
        font-size: 24px;
      }
  
      input {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 16px;
        width: 300px;
  
        &:focus {
          border-color: #80bdff;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
      }
    }
  
    .container-taches {
      .affaire-details {
        background-color: #fff;
        padding: 15px;
        margin-bottom: 10px;
        border-radius: 4px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  
        h2 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 15px;
          font-size: 20px;
          color: #333;
  
          .aller-affaire-btn {
            padding: 6px 12px;
            background-color: #007bff;
            color: #fff;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            transition: background-color 0.2s;
  
            &:hover {
              background-color: #0056b3;
            }
          }
        }
  
        .liste-details {
          margin-bottom: 10px;
          cursor: pointer;
  
          h3 {
            font-size: 18px;
            color: #555;
          }
  
          .element-details {
            display: flex;
            flex-direction: column;
            padding: 10px;
            background-color: #e9ecef;
            border-radius: 4px;
            margin-bottom: 5px;
            .element-details {
              display: flex;
              flex-direction: column;
              margin-bottom: 10px; /* Espace entre les éléments */
            }
            
            .top-element-details {
              display: flex;
              justify-content: space-between; /* Nom à gauche, état à droite */
              margin-bottom: 5px; /* Espace entre le haut et le bas si la description existe */
            }
            .element-value {
            color: #5a6268; 
            }
            .etat-non-commence {
              color: red;
            }
            
            .etat-en-cours,
            .etat-demande-info {
              color: orange;
            }
            
            .etat-termine {
              color: green;
            }
            p {
              font-size: 16px;
              color: #333;
  
              &:not(:last-child) {
                margin-bottom: 5px;
              }
            }
          }
        }
  
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}