/* styles pour ReactTooltip */
.__react_component_tooltip {
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
}

// Variables Sass pour le thème vert
$bg1-green: #2f4b52;
$bg2-green: #618587;
$bg3-green: #99bbab;
$bg4-green: #2e666e;
$c1-green: #f7f0cf;
$c2-green: #e0e0e0;
$bgError: #ef5350;
$cError: #ef5350;
$bgSuccess: #4caf50;
$cSuccess: #2e7d32;
$bgWarning: #ff9800;
$cWarning: #ed6c02;
$bgInfo: #42a5f5;
$cInfo: #1976d2;
;

// Variables Sass pour le thème bleu 
$bg1-blue: #123456;
$bg2-blue: #789abc;
$c1-blue: #f0e0d0;
$c2-blue: #383838;
$bgError: #ef5350;
$cError: #ef5350;
$bgSuccess: #4caf50;
$cSuccess: #2e7d32;
$bgWarning: #ff9800;
$cWarning: #ed6c02;
$bgInfo: #42a5f5;
$cInfo: #1976d2;

// Variables Sass pour le thème blanc
$bg1-white: #ffffff; // Fond principal
$bg2-white: #f8f9fa; // Fond secondaire (légèrement gris)
$bg3-white: #e9ecef; // Fond des conteneurs, par exemple
$bg4-white: #dee2e6; // Bordures ou séparations légères
$c1-white: #212529; // Couleur principale du texte
$c2-white: #495057; // Couleur secondaire du texte (pour moins d'importance ou des sous-titres)
$bgError: #ef5350; // Arrière-plan des messages d'erreur
$cError: #fff; // Couleur du texte pour les messages d'erreur
$bgSuccess: #4caf50; // Arrière-plan des messages de succès
$cSuccess: #fff; // Couleur du texte pour les messages de succès
$bgWarning: #ff9800; // Arrière-plan des avertissements
$cWarning: #fff; // Couleur du texte pour les avertissements
$bgInfo: #17a2b8; // Arrière-plan pour les infos
$cInfo: #fff; // Couleur du texte pour les infos

.white-theme {
  --bg1: #{$bg1-white};
  --bg2: #{$bg2-white};
  --bg3: #{$bg3-white};
  --bg4: #{$bg4-white};
  --c1: #{$c1-white};
  --c2: #{$c2-white};
  --bg1-light: #{lighten($bg2-white, 15%)};
  --bgError: #{$bgError};
  --cError: #{$cError};
  --bgSuccess: #{$bgSuccess};
  --cSuccess: #{$cSuccess};
  --bgWarning: #{$bgWarning};
  --cWarning: #{$cWarning};
  --bgInfo: #{$bgInfo};
  --cInfo: #{$cInfo};
}

.green-theme {
  --bg1: #{$bg1-green};
  --bg2: #{$bg2-green};
  --bg3: #{$bg3-green};
  --bg4: #{$bg4-green};
  --c1: #{$c1-green};
  --c2:#{$c2-green};
  --bg1-light: #{lighten($bg2-green, 15%)};
  --bgError: #{$bgError};
  --cError: #{$cError};
  --bgSuccess: #{$bgSuccess};
  --cSuccess: #{$cSuccess};
  --bgWarning: #{$bgWarning};
  --cWarning: #{$cWarning};
  --bgInfo: #{$bgInfo};
  --cInfo: #{$cInfo};

}

.blue-theme {
  --bg1: #{$bg1-blue};
  --bg2: #{$bg2-blue};
  --c1: #{$c1-blue};
  --c2: #{$c2-blue};
  --bg1-light: #{lighten($bg2-blue, 15%)};
  --bgError: #{$bgError};
  --cError: #{$cError};
  --bgSuccess: #{$bgSuccess};
  --cSuccess: #{$cSuccess};
  --bgWarning: #{$bgWarning};
  --cWarning: #{$cWarning};
  --bgInfo: #{$bgInfo};
  --cInfo: #{$cInfo};
}

// Global class to use when needed
.select-none {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

a {
  color: black;
  text-decoration: none;
}