@import "../../App.scss";

.preparation {
  display: flex;
  flex-direction: column;
  
  width: 100%;

  .containerpreparation {
    display: flex;
    height: 100%;

    .preparationcontainer {
      display: flex;
      flex-direction: column;
      background-color: var(--bg1);
      height: 100%;
      width: 100%;
      .toppreparation{
        display: flex;
      }
      .planningpreparation{

        flex-wrap: nowrap;
        width: 100%;
        height: 250px;
      }
      .planningpreparation2{

        flex-wrap: nowrap;
        width: 100%;
        height: 250px;
      }
    }
  }
}
