.assignmentContainer {
    .selectGroup {
      margin-bottom: 20px;
  
      h3 {
        margin-bottom: 10px;

        color: var(--c1);
      }
  
      .Select__control {
        // Stylisez le contrôle de sélection ici
      }
  
      .Select__menu {
        // Stylisez le menu déroulant ici
      }
    }
  
    button {
      padding: 10px 15px;
      background-color: #4CAF50;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #45a049;
      }
    }
  }
  