@import "../../../App.scss";

.TacheIdProgression{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    h3{
        font-size: 1.5em;
        margin: 15px;
    }
}