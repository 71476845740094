@import "../../../App.scss";

.dashboard-cards-container {
    width: 100%;
    height: 170px;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    background: linear-gradient(to right, var(--bg2), var(--bg1-light));
    padding-left: 15px;
    margin-left: 15px;
    margin-bottom: 0;
    padding-bottom: 0;

    .containe-affaire-dash-dc, .containe-tache-dash-dc {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        margin-right: 20px;
        position: relative;

        .title-container-card-dc {
            position: sticky;
            left: 0; /* Le titre reste collé à gauche */
            background-color: var(--bg1); /* Optionnel: pour garantir la visibilité du titre */
            z-index: 10; 
            border-radius: 0 0 20px 20px;
            padding-left: 30px;
            padding-right: 30px;
            h2 {
                font-size: 1.2rem;
                margin: 0 0 10px 0;
                color: var(--c1);
                position: sticky; 
                top: 0; 
                z-index: 10;
                background-color: var(--bg1);

            }
        }

        .list-card-dash-dc {
            display: flex;
            flex-wrap: nowrap;
            gap: 10px; 

            .card-container-item-dc {
                width: 150px;
                height: 90px;
                perspective: 1000px;
                cursor: pointer;

                &:hover {
                   
                    z-index: 999;
                }

                .card-front, .card-back {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    backface-visibility: hidden;
                    border-radius: 5px;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    transition: transform 0.6s, width 0.6s, height 0.6s, left 0.6s;
                }
                
                .card-front {
                    background-color: #ffffff;
                    transform: rotateY(0deg);
                    z-index: 2;
                    display: flex; 
                    flex-direction: column; 
                    justify-content: center; 
                    align-items: center; 
                    justify-content: space-between;
                    padding-top: 5px; 
                    padding-bottom: 5px;

                    p{
                        margin-bottom: 5px;
                        font-size: x-large;
                    }
                  
                }

                .card-back {
                    background-color: #f8f8f8;
                    transform: rotateY(180deg);
                    z-index: 1;
                    padding-top: 25px;
                    padding-bottom: 10px;
                    padding-left: 10px;
                    overflow: auto;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-around;
                }

                &.flipped .card-front {
                    transform: rotateY(-180deg);
                }

                &.flipped .card-back {
                    transform: rotateY(0deg);
                    width: 200px; // Largeur plus grande pour les détails
                    height: 100px; // Hauteur plus grande pour les détails
                    top: -30px; // Décalage vers le haut pour centrer verticalement
                    left: -45px; // Décalage vers la gauche pour centrer horizontalement

                    .item-one-dc {
                        margin: 5px 0;
                        padding: 5px;
                        border: 1px solid var(--bg1);
                        cursor: pointer;
                        transition: transform 0.2s ease, background-color 0.2s ease;
                        background-color: #ffffff;

                        &:hover {
                            transform: scale(1.1);
                            background-color: #eef2f7; 
                        }

                        &:nth-child(odd) {
                            background-color: #f4f6f8; 
                        }

                        &:last-child {
                            border-bottom: none; 
                        }
                    }
                }
            }
        }
    }
}