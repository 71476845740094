@import "../../App.scss";

.users {
  display: flex;
  height: calc(100vh - 50px);
  width: 100%;

  .container-users {
    display: flex;
    height: 100%;
    width: 100%;

    .containerusers {
      display: flex;
      background-color: var(--bg1);
      height: 100%;
      width: 100%;

      .left {
        flex: 5;
  
        overflow-y: auto;
        .header {
          display: flex;
          justify-content: space-around;
          background-color: #f0f0f0;
          padding: 10px;
          position: sticky;
          top: 0;
          z-index: 0;

          h3 {
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }

        .sectionrolePermissions {
          padding-bottom: 20px; // Espace supplémentaire en bas pour le défilement

          .roleSection {
            margin: 20px 0;
            
            .status-section-h2 {
              margin-bottom: 15px;
              text-align: center;
              background-color: var(--bg2);
              padding: 10px;
            }
            .cardsContainer {
              display: flex;
              flex-wrap: wrap;
              gap: 20px;

              &::-webkit-scrollbar {
                width: 10px;
              }

              &::-webkit-scrollbar-thumb {
                background: #888;
                border-radius: 10px;
              }

              &::-webkit-scrollbar-thumb:hover {
                background: #555;
              }
            }
          }
        }
      }
      .right {
        flex: 2;
        height: 100%;
        background-color: red;
      }
    }
  }
}
