.TachesNonCommence {
    display: flex;
    flex-direction: column;
    background: #f7f9fa; // Fond clair pour une bonne lisibilité
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;

    width: 90%; // Ajustez selon votre layout

  
    .header-TachesNonCommence {
      margin-bottom: 20px;
  
      h1 {
        color: #2f4b52; // Couleur pour le titre
        margin-bottom: 10px;
      }
  
      input {
        padding: 8px 10px;
        border: 2px solid #618587; // Bordure claire pour l'input
        border-radius: 5px;
        width: 100%;
        outline: none;
        &:focus {
          border-color: #2f4b52; // Assombrir la bordure lors du focus
        }
      }
    }
  
    .container-TachesNonCommence {
      flex-direction: column;
  
      .affaire-details {
        background: #ffffff;
        border-radius: 8px;
        padding: 15px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        margin-bottom: 20px; // Espace entre chaque bloc d'affaire
  
        h2 {
          cursor: pointer;
          color: #0056b3; // Couleur des liens
          margin-bottom: 10px;
          &:hover {
            text-decoration: underline;
          }
        }
  
        .element-details {
          background: #e9f5f9; // Fond très clair pour chaque tâche
          border-radius: 5px;
          padding: 10px;
          margin-top: 5px;
          cursor: pointer;
  
          &:hover {
            background: #d1e7ed; // Plus sombre au survol
          }
  
          .top-element-details {
            display: flex;
            justify-content: space-between;
            align-items: center;
  
            .nom-element {
              font-weight: bold;
              color: #2f4b52;
            }
  
            .etat-element {
              font-size: 0.8rem;
              padding: 3px 6px;
              border-radius: 12px;
              color: #fff;
              &.etat-non-commence {
                background-color:red;
              }
            }
          }
  
          .bottom-element-details {
            margin-top: 5px;
            font-size: 0.9rem;
            color: #4d4d4d;
  
            .nom-element {
                font-weight: bold;
                color: #2f4b52;
              }
            p {
              margin: 2px 0;
            }
          }
        }
      }
    }
  }
  
  