@import "../../../../App.scss";

.EnvoyerMessage {
  width: 600px;
  height: 600px;
  background-color: aliceblue;

  .header-em {
    width: 100%;
    display: flex;
    .btn-valid-em {
      width: 60px;
      height: 60px;
    }
    .rightchoix-em{
        display: flex;
        justify-content: space-around;
        width: 100%;
        .right-left-em{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
        }
        .right-right-em{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
        }
    }
  }
  .body-em {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 85%;

    .destinataire-em{
        display: flex;
        width: 100%;
    }
    .affaire-option-em {
      .affaire-details {
        display: flex;
      }
    }
    .contain-desc-em {
      display: flex;

      width: 100%;
      height: 100%;
    }
  }
}
