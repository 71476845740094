@import "../../App.scss";

.notifications{
    display: flex;
    flex-direction: column;
    height:  calc(100vh - 50px);
    width: 100%;
   
    .container-notifications{
        display: flex;
        height: 100%;
        
        .containerNotifications{
            background-color: var(--bg1);
            height: 100%;
            width: 100%;
            h1{
                text-align: center;
                margin: 20px 0;
                color: var(--c1);
            }
        }
    }
}
