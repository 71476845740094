@import "../../App.scss";

.reglagescontainer{
    display: flex;
    flex-direction: column;
    height:  calc(100vh - 50px);
    width: 100%;
    background-color: var(--bg1);

    .container-reglages{
        display: flex;
        height: 100%;
        width: 100%;

        .entrepriseformcontainer{
            display: flex;
            flex-direction: column;

            h2{
                color: var(--c1);
                margin: 10px 0;
            }
        }
    }
}