@import "../../App.scss";

.detailAffaireId{
    display: flex;
    flex-direction: column;
    height:  calc(100vh - 50px);
    width: 100%;
   
    .container{
        display: flex;
        height: 100%;
        
        .conatainerdetailAffaireId{
            background-color: var(--bg1);
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            
            .containertop{
                height: 50px;
                width: 100%;
            }
        }
    }
}