@import "../../../App.scss";

.affaireDocuments {
    padding: 20px;
    background-color: var(--bg1);
    color: var(--c1);

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.5rem;

        .path {
            display: flex;
            font-size: 1.5rem;
            font-weight: 400;
            gap: 3px;

            .item {
                display: flex;
                align-items: center;
                gap: 3px;

                &.clickable {
                    cursor: pointer;

                    &:hover {
                        color: var(--c2);
                    }
                }
            }
        }

        .actions {
            display: flex;
            gap: 12px;
        }
    }

        .email-service {
            cursor: pointer;
            font-weight: bold;
            text-decoration: underline;
        }

    .content {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .item {
            position: relative;
            cursor: pointer;
            display: flex;
            align-items: center; // Alignement des icônes et du texte sur la même ligne
            gap: 10px; // Espacement entre l'icône et le texte
            color: var(--c1);
            width: 100%; // Prendre toute la largeur disponible

            &.back {
                justify-content: center;
            }

            &.expiringSoon {
                color: #ff9800;
            }

            &.expired {
                color: #ef5350;
            }

            img, .MuiSvgIcon-root {
                width: 30px; // Taille des icônes
                height: 30px; // Hauteur ajustée pour les icônes
            }

            .name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis; // Ajoute "..." si le texte dépasse
                width: calc(100% - 40px); // Largeur ajustée pour inclure le texte et l'icône
            }

            &:hover {
                color: var(--c2);
            }
        }
    }
}