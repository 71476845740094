@import "../../../App.scss";

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75); // Fond semi-transparent
    z-index: 10000; // Assurez-vous que ce z-index est suffisamment élevé
}

.modal-content-custom {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%; // ou une autre largeur selon le besoin
    background: white;
    border-radius: 5px;
    outline: none;
    padding: 20px;
    .modal-header{
        display: flex;
        justify-content: space-between;
        z-index: 10000 !important;
    
        h2{
            padding-left: 50px;
            padding-bottom: 30px;
        }
        button{
            width: 30px;
            height: 30px;
        }
        .modal-content{
            display: flex;
            flex-direction: column;
        }
    }
}