@import "../../../App.scss";

.affichageTachePlanning {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .navaffichageTachePlanning {
    .tabs {
      display: flex;
      position: sticky;
      top: 0;
      justify-content: center; // Centrage horizontal
      align-items: center; // Centrage vertical
      background-color:  var(--c2);
      padding: 10px 0;
      box-sizing: border-box;
      z-index: 10;
      height: 30px;

      .tab {
        padding: 0 10px;
        cursor: pointer;

        &.active {
          border-bottom: solid 1px var(--c1);
          background-color: var(--bg1);
          padding: 6px;
          color: var(--c1);
        }
      }
    }
  }
  .partieHaute {
    display: flex;
    flex-direction: column;
    flex: 4;
    overflow-y: auto;
    width: 100%;
    height: 100%;

    .tabContent {
      flex: 1;
      height: 100%;
      width: 100%;
      
      .onglet1 {
        display: flex;
        height: 100%; 
        .onglet1a {
          flex: 4;
          max-height: 100%;
        }
        .onglet1b {
          flex: 2;
          max-height: 100%;
        min-width: 400px;
        }
      }
      .onglet2 {
        width: 100%;
        height: 100%;
        display: flex;
        .onglet2a {
          width: 100%;
          height: 100%;
          flex-wrap: nowrap;
          flex: 1;
        }
        .onglet2b {
          width: 100%;
          height: 100%;
          flex-wrap: nowrap;
          flex: 1;
        }
      }

      .onglet3 {
      }
    }
  }

  .partieBasse {
    flex: 3;
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }
}
