.ligneMoisContainer {
  display: flex; // Alignez les éléments horizontalement
  flex-wrap: nowrap; // Ne pas passer à la ligne même si les éléments débordent
 height: 23px;
 width: 100%;
 border-bottom: 1px solid var(--bg1);

  .monthContainer {
    display: flex;
    flex-shrink: 0;
    align-items: center; // Centrer verticalement
    justify-content: center; // Centrer horizontalement
    flex-wrap: nowrap;

    .mois {
      text-align: center; // Centrer le texte dans le bloc
    }
  }
}
