@import "../../../App.scss";

.AffichageWidgetRecap {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    max-width: 90%; // ou une largeur appropriée
    margin: auto;
    height: 80%;

    .date-selector-AffichageWidgetRecap {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
        gap: 20px;
    
        span.highlight-animate {
          animation: highlightAnimation 0.3s forwards; // Animation appliquée à la date
        }
    
        span{
            font-size: 20px;
        }
        button {
          background: linear-gradient(145deg, #007bff, #0056b3);
          border: none;
          color: white;
          padding: 10px 20px;
          border-radius: 50px;
          font-size: 16px;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
    
          &:hover, &:focus {
            transform: translateY(-2px);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          }
    
          &:active {
            transform: translateY(1px); // Assurez-vous de réinitialiser l'effet
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          }
    
          &:not(:active) { // Réinitialiser le hover quand le bouton n'est plus actif
            &:hover {
              background: linear-gradient(145deg, #0056b3, #007bff);
            }
          }
    
          &.active {
            background: linear-gradient(145deg, #0056b3, #007bff);
            color: #ffffff;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
          }
        }
      }
    
      @keyframes highlightAnimation {
        0% { color: #000; }
        50% { color: #007bff; }
        100% { color: #000; }
      }

    .tabs-AffichageWidgetRecap {
        display: flex;
        border-bottom: 2px solid #ccc; // Ligne sous les onglets
        button {
          background-color: #f0f0f0;
          border: none;
          padding: 10px 15px;
          cursor: pointer;
          border-top: 2px solid transparent;
          border-right: 2px solid #666;
          position: relative;
          outline: none;
          border-radius: 5px 5px 0 0; // Arrondir seulement les coins du haut
      
          // Icône dans les boutons
          svg {
            color: #666;
            font-size: 24px;
          }
      
          &:hover svg {
            color: #333;
          }
      
          // Style pour l'onglet actif
          &.active {
            background-color: white;
            border-color: #007bff;
            border-bottom: 2px solid white; // Masquer la bordure du bas pour fusionner avec le contenu
            margin-bottom: -2px; // Aligner parfaitement avec le contenu
      
            svg {
              color: #007bff;
            }
          }
      

      
          // Style pour augmenter la zone cliquable
          &:focus {
            z-index: 1; // Assurez que l'onglet actif reste au-dessus des autres
          }
        }
      }
  
    .content-AffichageWidgetRecap {
        width: 95%;
        height: 100%;
      background: #f7f7f7;
        padding: 0 20px 0 20px ;
      border-radius: 0 0 10px 10px;
      box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
        overflow-y: auto;
    }
  }
  