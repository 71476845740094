@import "../../../App.scss";

.TacheAPrendre {
  display: flex;
  flex-direction: column;
  background: linear-gradient(to right, var(--bg2), var(--bg1-light));
  height: 100%;
  width: 100%;
  flex-wrap: nowrap;

  .titleandformtap {
   height: 130px;
   padding-left: 10px;
   padding-right: 10px;

    h3 {
      margin-bottom: 10px;
    }

    .tache-formtap {
      display: flex;
      flex-direction: column;

      .btn-apercus-modif-supp{
          padding: 10px 15px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          background-color: #28a745;
          color: white;
      }

      .tache-formtaptop,
      .tache-formtapbottom {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;


        input[type="text"],
        .input-filetap {
          flex: 1;
          margin-right: 10px;
          padding: 8px;
          border: 1px solid #ddd;
          border-radius: 4px;

          &:last-child {
            margin-right: 0;
          }
        }

        .btn-submittap {
          padding: 10px 15px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          background-color: #28a745;
          color: white;
        }
      }
    }
  }

  .tableTacheAPrendre {
    overflow-x: auto; // Pour le défilement horizontal si nécessaire
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
    flex-wrap: nowrap;
    table {
      width: 100%; // Assurer que la table n'excède pas la largeur de son conteneur
      border-collapse: collapse;
      height: 30px;
      table-layout: fixed; /* Force la table à respecter les largeurs définies */

    }
    button {
      flex-basis: 10%;
      text-align: right;
      margin-left: auto;
      border: none;
      border-radius: 4px;
      padding: 5px 10px;
      margin-left: 10px;
      cursor: pointer;

      &:last-child {
        background-color: #dc3545;
        color: white;
        margin-left: 10px;
      }

      &:first-of-type {
        background-color: #ffc107;
        color: black;
      }
    }
  }
}
