@import "../../App.scss";

.messagerie {
    display: flex;
    flex-direction: column;
    background-color: var(--bg1);
    height: calc(100vh - 50px);
    width: 100%;

    .messageriecontainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        .top-messagerie {
            display: flex;
            width: 100%;
            height: 50px;
            background-color: blanchedalmond;
        }

        .containermessagerie {

            height: 100%;
            width: 100%;
            background-color: aqua;
        }
    }
}