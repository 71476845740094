.container-expiringFiles {
  background: linear-gradient(to right, var(--bg2), var(--bg1-light));
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 15px;
  padding: 15px;
  color: var(--c1);

  .title-expiringFiles {
    text-align: center;
    margin-bottom: 20px;

    h2 {
      margin: 0;
    }
  }

  .actionexpiringFiles {
    margin-bottom: 12px;

    .MuiInputBase-root {
      background: white;
    }
  }

  table {
    width: 100%;

    th {
      font-size: 20px;
      font-weight: bold;
    }

    td {
      text-align: center;
    }
  }
}