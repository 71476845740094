@import "../../../App.scss";

.calendrier-infos-container {
    display: flex;
    flex-direction: column;
    background: linear-gradient(to right, var(--bg2), var(--bg1-light));
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 15px;
    padding: 15px;
    height: 450px;
    width: 300px;
  
    // Style pour le calendrier (ajustez en fonction du calendrier spécifique que vous utilisez)
    .react-calendar {
      margin-bottom: 15px;
      width: 100%;
      border: none;
      border-radius: 15px;
  
      // Style pour les éléments internes du calendrier
      button {
        border: none;
        &:hover {
          background-color: var(--bg1);
        }
      }
    }
  
    .infos-generales {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
  
      p {
        text-align: center;
        margin: 5px 0;
        color: var(--c1);
      }
    }
  }
  