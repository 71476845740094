@import "../../../App.scss";

.AffichageTachePlanningNew {
  display: flex;
  flex-direction: column;
  height: 100%;

  .top-atpn {
    display: flex;
    justify-content: flex-start;
    height: 40px;
    background: linear-gradient(to right, var(--bg2), var(--bg1-light));
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 5px 15px 15px 15px; /* Haut, Droite, Bas, Gauche */
    padding: 5px 10px 10px 10px;
 

    .btn-base-atpn{

      display: flex;
      justify-content: flex-start;
      min-width: 550px;
      gap: 10px;

    .btn-top-atpn {
        flex-shrink: 0; // Empêche les boutons de rétrécir
        white-space: nowrap; // Empêche le texte du bouton de passer à la ligne
        padding: 8px 16px; // Espacement interne pour le bouton
        border: none;
        border-radius: 5px;
        background-color: var(--bg1);
        color: var(--c1);
        font-size: medium;
        cursor: pointer;
        transition: background-color 0.3s, transform 0.3s ease;

        &:hover {
          transform: scale(1.05); // Effet de zoom au survol
        }
    }
  }
  .liste-btn-atpn {
    display: flex;
    width: calc(100% - 600px);
    box-sizing: border-box;
  }
  }

  .planning-atpn{
    max-height: 100%;
  }

  .btn-ajout-tache-atpn{
    display: flex;
    width: 100%;
    background-color: var(--bg2);
    padding-bottom: 5px;
    padding-top: 5px;
    border-top: 1px solid var(--bg1);
    span{
        margin-left: 5px;
       
    }

    .btn-tache-atpn {
        border: none;
        border-radius: 5px;
        background-color: var(--bg1);
        color: var(--c1);
        font-size: medium;
        cursor: pointer;
        transition: background-color 0.3s ease;
        margin-left: 5px;
        &:hover {
          transform: scale(1.05); // Effet de zoom au survol
        }
      }
  }
   // Style pour le container du formulaire
   .form-container {
    position: fixed;
    right: 0;
    top: 0;
    background-color: white;
    border-radius: 10px 10px 0 0;
    max-width: 100vw; 
    max-height: 100vh;
    z-index: 1000;
    overflow: hidden;
  
    .form-header {
      cursor: move;
      background: linear-gradient(to right, var(--bg2), var(--bg1-light));
      border: solid 3px var(--bg1);
      border-radius: 10px 10px 0 0;
      color: var(--c1);
      padding: 10px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      .close-button {
        border: none;
        border-radius: 5px;
        background-color: var(--bg1);
        color: var(--c1);
        font-size: 20px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        padding: 5px 10px;
        align-items: center;
        &:hover {
          transform: scale(1.05); // Effet de zoom au survol
        }
      }
    }
  
    .form-body {
      overflow-y: auto;
      max-width: 100vw; 
      max-height: 100vh;
      
    }
  }
}
