@import "../../../../App.scss";

.nomtachemessage{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    width: 100%;
    background-color: aqua;
    .span-nomtachemessage{
        width: 120px;
        
    }
    .input-nomtachemessage{
        width: 100%;
        height: max-content;
    }
}