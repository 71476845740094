.info {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .title {
    font-weight: 600;
  }
}

.comments {
  .title {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 6px;
  }

  .content {
    display: flex;
    gap: 5px;
    flex-direction: column-reverse;
    max-height: 250px;
    overflow: auto;

    .noComment {
      font-size: 17px;
      padding: 20px;
      text-align: center;
      font-weight: bold;
    }

    .comment {
      display: flex;
      gap: 1px;
      flex-direction: column;
      align-items: flex-start;
      // justify-content: flex-start;
      width: 100%;

      &.mine {
        align-items: flex-end;
      }

      .buble {
        background-color: #f2f2f2;
        border: 1px solid #919191;
        border-radius: 15px;
        padding: 5px 10px;
      }

      .infos {
        color: #383838;
        font-size: 10px;
      }
    }
  }
}