@import "../../App.scss";

.AffairePlanningId {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px);
  width: 100%;
  .container {
    display: flex;
    height: 100%;
    width: 100%;
 
    .containerAffairePlanningId {
      background-color: var(--bg1);
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      .topcontainerAffairePlanningId{
        display: flex;
      }
    }
  }
}
