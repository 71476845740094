@import "../../../App.scss";

.containReceptionMessagerie {
height: 100%;
width: 100%;


  .filtres {
    width: 100%;
    display: flex;
    justify-content: space-around;
    background-color: lightgray;
    padding: 10px;
    box-sizing: border-box;

    button {
      cursor: pointer;
    }
  }

  .liste-taches-crm {
    width: 100%;
    overflow-y: auto;
    height: calc(100% - 40px);
    border-top: 1px solid gray;
    box-sizing: border-box;
    overflow-x: hidden;

    .tache-class-crm {
      width: 100%;
      display: flex;
      border-bottom: 1px solid lightgray;
      padding: 10px;
      box-sizing: border-box;
      cursor: pointer;
      transition: transform 0.2s ease, box-shadow 0.2s ease; /* Ajouter une transition pour le survol */
    
      &:hover {
        transform: scale(1.03);/* Agrandir l'élément légèrement */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Ajouter une ombre pour un effet de profondeur */
      }

      .user-tache-crm {
        width: 170px;
        flex-shrink: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      
      .nom-et-desc-crm {
        display: flex;
        flex-grow: 1;
        margin-left: 10px;
        margin-right: 10px;
        overflow: hidden;
      
        
        .nom-tache-crm {
          width: 400px;
          flex-shrink: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .description-tache-crm {
          margin-left: 10px;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .date-tache-crm {
        flex-shrink: 0;
        margin-left: auto;
        white-space: nowrap;
      }

      &.non-lu {
        font-weight: bold;
        background-color: var(--bg2);
      }
    }
  }
}