@import "../../App.scss";

.HomeNew {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 50px);
    background-color: var(--bg1);
    width: 100%;

    .contain-HomeNew {
        display: flex;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        flex-wrap: nowrap;

        .widget-contain-HomeNew{
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            flex-wrap: nowrap;
            height: 100%;
            width: 100%;
            .selectandgo-home {
                width: 500px; // Largeur fixe
                padding-top: 30px;
                padding-left: 30px;
                box-sizing: border-box; // Inclut le padding dans la largeur de 500px
                flex: none; // Empêche le flex-grow ou flex-shrink
            }
        
            .affichage-recap-homeNew {

                width: 100%;
                height: 100%;
                padding-top: 30px;
                box-sizing: border-box; // Inclut tout padding ou bordure dans la hauteur
            }
        }
    }
}
