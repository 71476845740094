@import "../../../App.scss";

.topBarPage {
  height: 50px;
  width: 100%;
  display: flex;
  //margin-left: 30px;
  margin-top: 10px;

  .left {
    flex: 5;
    color: var(--c1);
    font-weight: bold;
    padding-left: 30px;
  }
  .middle {
    flex: 5;
    display: flex;
    align-items: center;
    justify-content: space-around;
    button {
        height: 30px;
        width: 130px;
        margin-top: 10px;
        margin-bottom: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 20px;
        cursor: pointer;
        background-color: var(--bg1);
        color: var(--c1);
        font-weight: 200;
        letter-spacing: 1px; 
        transition: background-color 0.3s ease;
      }
      button:hover{
        background-color: var(--bg2);
      }
      .active{
        background-color: var(--bg2);
      }
  }
  .right {
    flex: 2;
    .search {
        width: 100%;
      padding-top: 10px;
      padding-bottom: 5px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid var(--c1);
      .searchimg {
        color: var(--c1);
      }
      input {
        background-color: transparent;
        color: var(--c1);
        border: none;
        outline: none;
        box-shadow: none;
      }
      .custom-input::placeholder {
        color: var(--c1);
      }
    }
  }
}
