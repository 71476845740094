@import "../../../App.scss";

.TacheFormPlanning {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background: linear-gradient(to right, var(--bg2), var(--bg1-light));
  height: 100%;
  width: 100;
  display: flex;
  flex-direction: column;

  .react-datepicker-popper {
    z-index: 9999 !important;
  }

  h2 {
    margin-bottom: 10px;
  }
  .dateandselecttfp {
    display: flex;
    justify-content: space-around;
  }
  .form-grouptfp {
    label {
      display: block;
      margin-bottom: 5px;
    }

    input[type="text"],
    textarea,
    select {
      width: 100%;
      padding: 8px 15px;
      border-radius: 5px;
      border: 2px solid #9e9e9e; // Bordure grise
      outline: none;
      transition: border-color 0.3s ease, box-shadow 0.3s ease;
      box-sizing: border-box;
      &:focus {
        border-color: var(--bg1); // Couleur de la bordure au focus
        box-shadow: 0 0 8px var(--bg4); // Ombre extérieure pour mettre en évidence
      }
    }

    textarea {
      resize: vertical;
    }
    &.duration-field {
      display: flex;
      align-items: center;

      label {
        margin-right: 10px;
        white-space: nowrap;
      }

      input[type="number"] {
        width: 70px;
        padding: 5px;
        border: 1px solid #ddd;
        border-radius: 4px;
      }
    }
  }

  .submit-buttontfp {
    margin-left: 130px;
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: large;
    background-color: #4caf50; // Vert pour "Modifier"
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  
    &:hover {
      background-color: #388e3c; // Un vert plus foncé au survol
      transform: scale(1.05); // Effet de zoom légèrement
    }
  
    &:active {
      transform: scale(0.95); // Effet de pression
    }

    &:active {
      background-color: #1e7e34; // Une couleur encore plus foncée lorsqu'on clique sur le bouton
      transform: scale(
        0.98
      ); // Légère réduction de taille lorsqu'on clique sur le bouton
    }

    &:focus {
      outline: none; // Supprime l'outline par défaut pour les éléments focus
      box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5); // Ajoute un halo pour l'accessibilité
    }
  }
  &.large-layout {
    .form-grouptfp,
    .form-grouptfp.duration-field {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      label {
        margin-right: 10px;
        white-space: nowrap;
      }

      input[type="text"],
      textarea,
      select,
      input[type="number"] {
        flex-grow: 1;
        padding: 8px;
        border: 1px solid #ddd;
        border-radius: 4px;
      }

      textarea {
        resize: vertical;
      }
    }

    .biggroupetfp{
      display: flex;
      justify-content: space-around;
    }
  }
}
