@import "../../../App.scss";

.pauseForm {
    .formGroup {
        margin-bottom: 20px;

        label {
            display: block;
            margin-bottom: 5px;
        }

        input, select {
            width: 100%;
            padding: 10px;
            margin-top: 5px;
        }
    }

    button {
        padding: 10px 20px;
        cursor: pointer;
    }
}
