.liste-details {
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
    h2 {
      color: #333;
      font-size: 24px;
      margin-bottom: 20px;
    }
  
    .top-liste-details{
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        .right-liste-details{
            display: flex;
            gap: 10px;
        }
    }
    .form-container {
      position: fixed;  // Utiliser 'fixed' pour garder le formulaire au-dessus de tout
      top: 10px;  // Ajuster en fonction de l'en-tête de votre application si nécessaire
      left: 50%;
      transform: translateX(-50%);
      width: 80%;  // Ajuster en fonction de la largeur souhaitée
      background: white;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      z-index: 100;
    }
  
    .elements-list {
      margin-top: 15px;  // Assurez-vous que la liste commence en dessous du formulaire
      overflow-y: auto;
      padding: 10px;
      margin-bottom: 50px;
    }
  
    .element-item {
      background-color: #fff;
      padding: 10px;
      border-radius: 4px;
      margin-bottom: 10px;
  
      .element-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    
        .checkbox {
          flex: 0 0 auto; // Ne grandit pas, ne rétrécit pas, et garde sa taille initiale
        }
    
        .titre-element {
          font-size: 1.2em;
          color: #333;
          flex: 1 1 auto; // Permet au titre de prendre l'espace disponible
          margin-left: 10px; // Ajoute un peu d'espace entre la checkbox et le titre
        }
    
        .date-realisation {
          color: #666;
          font-size: 0.9em;
          flex: 0 1 auto; // Ne grandit pas, peut rétrécir si nécessaire
          margin-left: 20px; // Assure un espacement entre le titre et la date
        }
    
        .actions {
          display: flex;
          flex: 0 0 auto; // Ne grandit pas, ne rétrécit pas, et garde sa taille initiale
          margin-left: 20px; // Assure un espacement entre la date et les boutons
        }
      }
  
      .description {
        margin-top: 5px;
        color: #666;
      }
    }
  }
  