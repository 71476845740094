@import "../../App.scss";

.barreTache {
    display: flex;
  
    .jourTache {
      flex-shrink: 0;
      flex-wrap: nowrap;
      height: 25px;
      box-sizing: border-box;
      border-top: 1px solid var(--bg1);
      border-right: 1px solid var(--bg1);
      padding-right: 1px;
      cursor: pointer;
  
      &.highlight {
        &.green { // Tâche terminée
          background-color: green;
        }
  
        &.orange { // Tâche en cours
          background-color: orange;
        }
  
        &.red { // Tâche non commencée
          background-color: red;
        }
      }
  
      &.weekend,
      &.holiday {
        background-color: #f0f0f0;
      }
    }
  }