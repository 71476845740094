@import "../../../App.scss";

.container-ExpiringFilesRecap {
    background-color: #ffffff; // Fond blanc pour la clarté
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Ombre plus douce
    margin-top: 20px;
  
    .title-ExpiringFilesRecap {
      margin-bottom: 15px;
      h2 {
        color: $bg1-green; // Utilisation de votre couleur personnalisée
        font-size: 1.25rem;
        margin: 0;
      }
    }
  
    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 10px;
  
      th, td {
        text-align: left;
        padding: 10px;
        border-bottom: 1px solid $bg2-green; // Bordure subtile
  
        &:last-child {
          text-align: center;
        }
      }
  
      th {
        background-color: $bg1-green;
        color: white;
        font-weight: 500;
      }
  
      tbody tr:nth-child(odd) {
        background-color: #f8f8f8; // Couleur de fond plus douce pour les lignes impaires
      }
  
      tbody tr:nth-child(even) {
        background-color: white;
      }
  
      td[colspan="4"] {
        text-align: center;
        padding: 10px;
        color: #666;
        font-style: italic;
        font-size: 0.875rem;
      }
    }
  }
  