.rolePermissions {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px);
  width: 100%;

  .container {
    display: flex;
    height: 100%;

    .containerRolePermissions {
      width: 100%;
      background-color: var(--bg1);
      padding: 10px;
      overflow-y: auto; // Assurez-vous que le contenu défile correctement
display: flex;
flex-direction: column;
      .viewTogglerolePermissions {

        .viewToggle {
          display: flex;
          justify-content: flex-end;
          margin-bottom: 10px;
        }
      }
      .renderrolePermissions {
        overflow-y: auto;
        .cardView,
        .listView {
          display: flex;
          flex-direction: column;
         
          height: 100%; // Utilisez 100% de la hauteur disponible

          .carditem {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            justify-content: space-around;
          }
        }
      }
    }
  }
}
