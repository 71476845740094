@import "../../App.scss";

.calandar {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px);
  width: 100%;
  .full-container-calandar {
    display: flex;
    height: 100%;

    .container-calandar {
      flex-grow: 1;
      padding: 20px;
      background-color: var(--bg1);

      .title-calandar {
        h1 {
          color: var(--c1);
          margin-bottom: 20px;
          // Additional title styles...
        }
      }
      .btn-group-calandar{
       display: flex;
       justify-content: space-evenly;
       align-items: center;
      }
      .rbc-calendar {
        border: 1px solid var(--c1);
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

        .rbc-header {
          color: var(--c1);
        }

        .rbc-label {
          color: var(--c1);
        }

        .rbc-month-row{
          color: var(--c1);
        }
        .rbc-today {
          background-color: var(--bg2);
            label{
              color: var(--bg1);
            }
          
        }

        .rbc-event {
          background-color: var(--bg2);
          color: var(--c1);
        }

        .rbc-toolbar {
          button {
            background-color: var(--bg2);
            color: var(--c1);
            border-radius: 5px;
            &:hover {
              background-color: var(--c2);
            }
          }
          .rbc-toolbar-label {
            color: var(--c1);
          }
        }
      }
    

      .btn-go-calandar, .btn-aujourdui-calandar, .btn-choose-calandar {
        border: 1px solid var(--c1);
        color: var(--c1);
        background-color: var(--bg2);
        padding: 8px 16px;
        margin: 0 5px;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
          background-color: var(--c2);
          color: var(--bg1);
        }
      }

      .label-calandar {
        margin: 0 10px;
        color: var(--c1);
        font-weight: bold;
      }
    }
  }
}
  

