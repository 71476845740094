@import "../../../App.scss";

.userForm {
  form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 20px;

    label {
      margin-bottom: 5px;
      font-weight: bold;
      color: #333;
    }

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="password"] {
      padding: 8px;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      width: calc(100% - 18px); // Adjust based on padding and border

      &:focus {
        outline: none;
        border-color: #0056b3;
        box-shadow: 0 0 0 2px rgba(0, 86, 179, 0.25);
      }
    }

    input[type="checkbox"] {
      margin-right: 5px;
    }

    .info-deneral-utilisateur-form,
    .role-utilisateur-form,
    .adress-utilisateur-form {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .role-utilisateur-form {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      label {
        margin-right: 10px; // Space between checkbox and label
      }
    }

    button {
      padding: 10px 15px;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}
