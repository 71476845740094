@import "../../../App.scss";

.aPrendreOneTacheId {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: linear-gradient(to right, var(--bg2), var(--bg1-light));
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 100%;
  width: 100%;
  flex-wrap: nowrap;

  .topaPrendreOneTacheId {
    .tacheInfo {
      margin-top: 10px;
      margin-bottom: 20px;
      margin-left: 10px;

      h2 {
        margin-bottom: 5px;
      
      }

      p {
        margin-bottom: 5px;
      
      }
    }

    .materielForm {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      input[type="text"],
      input[type="number"],
      select {
        flex: 1;
        margin-right: 10px;
        padding: 8px;
        border: 1px solid #ddd;
        border-radius: 4px;

        &:last-child {
          margin-right: 0;
        }
      }

      .btn-submittap {
        padding: 10px 15px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        background-color: #28a745;
        color: white;
      }
    }
  }


    .materielListBody {
      overflow-y: auto;
      max-height: 300px;

      .materielListItem {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid #ddd;
    
        &:nth-child(odd) {
          background-color: #fff;
        }
    
        &:nth-child(even) {
          background-color: #f5f5f5;
        }
    
        // Ajout pour les span
        span {
          flex-grow: 1;
          margin-right: 10px;
    
          &:first-child {
            flex-basis: 40%;
            text-align: left;
            word-wrap: break-word;
            max-lines: 2;
          }
    
          &:nth-child(2), &:nth-child(3) {
            flex-basis: 20%;
          }
        }
    
        button {
          flex-basis: 10%;
          text-align: right;
          margin-left: auto;
          border: none;
          border-radius: 4px;
          padding: 5px 10px;
          margin-left: 10px;
          cursor: pointer;
    
          &:last-child {
            background-color: #dc3545;
            color: white;
            margin-left: 10px;
          }
    
          &:first-of-type {
            background-color: #ffc107;
            color: black;
          }
        }
      }
    
      .noMateriel {
        text-align: center;
        padding: 10px;
        color: #666;
      }
    }
}