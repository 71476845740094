@import "../../../App.scss";

.AffaireResume {
    background-color: var(--c2);

    .print-button{
      .btn-imprim-affaireresume{
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        background-color: var(--c1);
        color: var(--bg1);
        font-size: medium;
        cursor: pointer;
        transition: background-color 0.3s ease;
    
        &:hover {
            transform: scale(1.05); // Effet de zoom au survol
          }
      }
    }
    
    .title-rapport-affaire-resume {
        padding: 15px;
      h2 {
        color: var(--c1);
        background-color: var(--bg1);
        padding: 10px;
        border-radius: 10px;
      }
    }
  
    .AffaireResume-rapport-depart {
      background: var(--c2);
      color: var(--bg1);
      padding: 20px;
      margin: 10px 0;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
  
    .header-rapport-depart, .equipe-prevus-rapport-depart, .contact-client-rapport-depart, .date-chantier-prevus-rapport-depart, .a-prendre-rapport-depart {
      margin-bottom: 20px;
      .header-print {
        display: none; /* Cache le h2 destiné à l'impression */
      }
      h2, h3 {
        color: var(--c1);
        background-color: var(--bg1);
        padding: 10px;
        border-radius: 10px;
      }
  
      p, li {
        color: var(--bg1);
        padding: 10px;
        border-radius: 5px;
      }
    }
  
    .materiel-prevus-rapport-depart {
      h3 {
        color: var(--c1);
        background-color: var(--bg1);
        padding: 10px;
        border-radius: 10px;
      }
  
      .liste-tache-rapport-depart{
      .title-liste-tache-rapport-depart{
        color: var(--c1);
        background-color: var(--bg1);
        padding: 10px;
        border-radius: 10px;
    }
    .detail-ta-ra-de{
        margin-left: 40px;
        padding: 5px;
        p{
            padding: 5px;
        }
      .a-prendre-pour-taches-rapport-depart {
        ul {
          list-style-type: none;
          padding: 0;
  
          li {
            color: var(--bg1);
            border-radius: 5px;
          }
        }
      }
    }
    }
    }
  }
    @media print {
      .AffaireResume-rapport-depart {
        box-shadow: none;

        .header-rapport-depart{
          h2{
            background-color:  #618587;
          }
          .header-normal {
            display: none; /* Cache le h2 normal lors de l'impression */
          }
          .header-print {
            display: block; /* Affiche le h2 destiné à l'impression */
          }
        }

.equipe-prevus-rapport-depart, .contact-client-rapport-depart, .date-chantier-prevus-rapport-depart, .a-prendre-rapport-depart {
          h2, h3 {
            background-color:  #618587;
            width: 250px;
          }

        }
        .title-liste-tache-rapport-depart{
          background-color: #618587;
        }
      }
    }
