@import "../../../../App.scss";

.HomePageDeReception {
  display: flex;
  width: 100%;
  height: calc(100vh - 100px);
  .side-home-pdr {
    height: 100%;
  }

  .contain-home-pdr {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;

    .top-home-pdr {
      height: 35px;
      width: 100%;
      background-color: beige;
      box-sizing: border-box;
    }
    .middle-parent-pdr{
      height: 100%;
      width: 100%;/* Permet au conteneur de prendre l'espace restant */
        display: flex;
        justify-content: center; /* Centre horizontalement */
        align-items: center; /* Centre verticalement */
    
        .middle-home-pdr {
            height: 97%;
            width: 97%;
            background-color: cadetblue;
            border-radius: 10px;
        }
    }

  }
}
