@import "../../App.scss";

.home {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 50px);
    width: 100%;

    .container-home {
        display: flex;
        height: 100%;
        width: 100%;
   

        .widget-home {
            background-color: var(--bg1);
            display: flex;
            flex-direction: column;
            height: 100%;
            flex-grow: 1;
            overflow-x: hidden; 
         
            
            .top-widget-home{
                height: 60px;
                width: 100%;
                background-color:var(--bg1);
            }

            .niveau-deux-contain-general-home{
                height: 100%;
                display: flex;
              
            .contain-niveau-deux-gauche{
                display: flex;
                .niveau-deux-gauche-gauche-home{
                    display: flex;
                    flex-direction: column;
                    .niveau-deux-haut-gauche-home{
                        display: flex;
                    }
                }
            }
            .contain-niveau-deux-droite{
                height: 100%;
                width: 300px;
              
            }
        }
            .dashboardCards-composant-home {
                width: 100%;
                min-height: 170px;
                overflow-x: auto;
                overflow-y: hidden;
                margin-top: auto;
            }
        }
    }
}