@import "../../../App.scss";

.BoiteDeReponseMessage {
  display: flex;
  flex-direction: column;

  .header-bdrm {
    display: flex;

    .btn-valid-bdrm {
      height: 60px;
      width: 60px;
    }
  }
  .body-bdrm {
    display: flex;
    flex-direction: column;

    .destinataire-em{
        display: flex;
        align-items: center;

        span{
            margin-left: 5px;
        }
    }

    .textarea-bdrm{
        height: 80%;
        width: 80%;
    }
  }
}
