@import "../../App.scss";

.tacheInfo-tachespl {
    display: flex;
    flex-direction: column;
    
    .nomTache-tachespl{
        display: flex;
    align-items: center; /* Aligner le contenu à gauche */
    justify-content:flex-start; /* Centrer le contenu verticalement */
    height: 25px;
    width: 100%;
    padding-left: 10px;
    box-sizing: border-box;
    border-top: 1px solid var(--bg1);
    white-space: nowrap; /* Empêcher le texte de passer à la ligne */
   
}
}