.generic-list {
  display: flex;
  flex-direction: column;

  .search-container {
    background-color: #f5f5f5; // Couleur de fond pour la zone de recherche
    z-index: 1;
    position: sticky;
    top: 0;
    input[type="text"] {
      width: 400px; // Agrandir en longueur pour occuper toute la largeur du conteneur
      padding: 5px; // Augmenter la hauteur via le padding
      border: 1px solid #ccc; // Style de bordure (ajustez selon vos préférences)
      border-radius: 4px; // Arrondir les coins
      font-size: 16px;
      box-sizing: border-box; // Augmenter la taille de la police si nécessaire
    }
  }
  .header-row {
    display: flex;
    background-color: #e0e0e0; // Couleur de fond pour les en-têtes
    //padding: 10px;
    position: sticky;
    top: 30px; // Ajustez selon la hauteur de la zone de recherche
    z-index: 1;
    height: 30px;
    box-sizing: border-box;
    flex-wrap: nowrap;

    .column,
    .actions-column {
      flex: 1; // Ajustez la répartition de l'espace
      padding: 5px;
      text-align: left;
      border-right: 1px solid #ccc;
      box-sizing: border-box;
    }

    .actions-column {
      display: flex;
      align-items: center; // Pour aligner verticalement
      justify-content: flex-end; // Pour aligner à droite
    }
  }

  .column {
    flex: 1; // Ajustez la répartition de l'espace
    padding: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; // Permet le retour à la ligne
    min-height: 30px;
    max-height: 60px; // Hauteur maximale avant de couper, ajustez si nécessaire
    line-clamp: 3; // Nombre maximal de lignes
    box-orient: vertical;
    display: -webkit-box;
    
  }

  .actions-column {
    flex-basis: 20%; // Espace pour la colonne des actions
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-row {
    background-color: var(--bg4); // Couleur de fond pour les en-têtes
    font-weight: bold;
    color: var(--c1);
  }

  .data-row {
    display: flex;
    background-color: var(--bg1);
    color: var(--c1); 
    &:nth-child(odd) {
      background-color: var(--bg2);// Couleur de fond pour les lignes impaires
      color: var(--c1); 
    }
  }
}
