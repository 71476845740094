@import "../../App.scss";



.ligneJourContainer-tachespl {
  display: flex; // Alignez les éléments horizontalement
  flex-wrap: nowrap; // Ne pas passer à la ligne même si les éléments débordent
    height: 25px;
    width: 100%;
    border-bottom: 1px solid var(--c1);
  .monthDays-tachespl {
    display: flex;
    flex-wrap: nowrap;
    .jour-tachespl {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      border-top: 1px solid var(--c1);
      border-right: 1px solid var(--c1);
      background-color: var(--bg1);
      color: var(--c1);
      font-size: small;
      &.weekend-tachespl,
      &.holiday-tachespl {
        background-color: #f5f5f5; // Fond plus foncé pour les week-ends et jours fériés
        color: var(--bg1);
        box-sizing: border-box;
      
        border-right: 1px solid var(--bg1);
      }
    }
  }
}
