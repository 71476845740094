@import "../../App.scss";

.topbar-container {
  position: relative;
  height: 50px;
  width: 100%;
  //background: linear-gradient(to right,#496a6b, lighten(#496a6b, 15%));
  //background-color: var(--bg1-light);
  background: linear-gradient(to right, var(--bg2), var(--bg1-light));
  display: flex;
  justify-content: space-between;
  .icon-topbar {
    display: flex;
    align-items: center;

    .img-top-bar {
      width: 30px;
      height: 30px;
      padding: 10px;
      color: var(--c1);
      cursor: pointer;
      transition: background-color 0.3s; // Ajout d'une transition pour une meilleure expérience utilisateur

      &:hover {
        background-color: var(--bg1);
      }
    }

    .dropdown-menu {
      display: block;
      position: absolute;
      top: 100%; // Positionne le menu juste en dessous du bouton
      left: 0;
      border: 1px solid #ccc;
      background-color: black;
      color: #f2f2f2;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
      z-index: 9999;

      button {
        display: block;
        width: 100%;
        padding: 10px;
        background: none;
        border: none;
        text-align: left;
        cursor: pointer;
        transition: background-color 0.3s; // Ajout d'une transition pour une meilleure expérience utilisateur

        &:hover {
          background-color: #f2f2f2;
        }
      }
    }

    .active-top-bar{
      background-color: var(--bg1);
      color: var(--c1);
      border-radius: 20px 0 0 0;
      border-bottom: 1px solid #f7f0cf;
    }
  }
  .userconnect {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .titleuserco {
      color: var(--c1);
      font-weight: 500;
    }

    .userconnect__name {
      color: var(--c1);
    }
    .img-top-bar {
      width: 30px;
      height: 30px;
      padding: 10px;
      color: var(--c1);
      cursor: pointer;
      transition: background-color 0.3s; // Ajout d'une transition pour une meilleure expérience utilisateur

      &:hover {
        background-color: var(--bg1);
      }
    }
  }
}
