@import "../../App.scss";

.containerentrepriseForm {
    display: flex;
    flex-direction: column;
    max-width: 500px; 
    margin: 0 auto; 
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
    border-radius: 8px; 
  
    h3{
        color: var(--c1);
        margin-bottom: 20px;
    }
    .block-contain-entreprise {
        display: flex;
      margin-bottom: 20px; 
  
      span {
        display: block; 
        margin-bottom: 5px;
        color: var(--c1);
        width: 350px;
      }
  
      input {
        width: 100%;
        padding: 8px; 
        border: 1px solid #ccc; 
        border-radius: 4px; 
  
        &:focus {
          border-color: #007bff; 
          outline: none; 
          box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
        }
      }
    }
  
    button {
        margin-left: 10px;
        padding: 10px 20px;
        margin-bottom: 20px;
        border: none;
        background-color: var(--bg2);
        color: var(--c1);
        border-radius: 5px;
        cursor: pointer;
        &:hover {
          background-color: var(--c2); // Couleur de fond au survol
          color: var(--bg1);
        }
    }
  }