@import "../../../App.scss";

.affectation-utilisateur {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    box-sizing: border-box;

  
    h2 {
      color: #333;
      margin-bottom: 20px;
    }
  
    .head-affectation-utilisateur,
    .select-affectation-utilisateur {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
  
      label {
        margin-right: 10px;
        margin-left: 10px;
      } 
      button {
        margin-left: auto;
        padding: 8px 16px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
  
        &:hover {
          background-color: #0056b3;
        }
      }
    }
  
    .middle-affectation-utilisateur{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      margin-top: 10px;

      .m-middle-affectation-utilisateur{
        display: flex;
        gap: 10px;

        .number-affectation-utilisateur{
          width: 70px;
        }
      }
    }
    .select-affectation-utilisateur {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
  label {
    margin-bottom: 5px;
    margin-top: 5px;
    }
    .react-select {
        width: 100%;
      }
    }
    button {
        margin-left: auto;
        padding: 8px 16px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
  
        &:hover {
          background-color: #0056b3;
        }
      }
  }