
.tacheInfo {
    display: flex;
    flex-direction: column;
    
    .nomTache{
    align-items: flex-start; /* Aligner le contenu à gauche */
    justify-content: center; /* Centrer le contenu verticalement */
    height: 25px;
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid var(--bg1);
    white-space: nowrap; /* Empêcher le texte de passer à la ligne */
}
}