.boiteLectureMessage {
    display: flex;
    flex-direction: column;
    height: 100%; // Adapter selon la disposition de votre container parent
    overflow-x: hidden;

    .barreOptions {
        display: flex;
        align-items: center;
        padding: 10px;
        background-color: #f5f5f5;
        border-bottom: 1px solid #ddd;

        .boutonRetour {
            padding: 5px 10px;
            margin-right: 10px;
            background-color: #007bff;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            &:hover {
                background-color: #0056b3;
            }
        }
    }

    .contenuMessage {
        flex-grow: 1;
        overflow-y: auto;
        padding: 15px;

        .enTeteMessage {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;

            .emetteur {
                font-weight: bold;
            }

            .dateEnvoi {
                color: #666;
            }
        }

        .titreMessage {
            font-size: 1.2em;
            margin-bottom: 10px;
        }

        .corpsMessage {
            white-space: pre-wrap; // Pour conserver le formatage du message, si nécessaire
        }
    }
}