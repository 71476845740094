@import "../../../App.scss";

.details-affaire-tache-container {
  display: flex;
  flex-direction: column;
  background: linear-gradient(to right, var(--bg2), var(--bg1-light));
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 15px;
  margin-bottom: 0;
  padding: 15px;

  overflow: hidden;
    height: 450px;
    width: 300px;
  .close-button-datc {
    align-self: flex-end;
    cursor: pointer;
    background-color: transparent;
    border: none;
    font-size: 1.2rem;
  }

  .affaire-photo-tache-datc, .affaire-photo-affaire-datc {
    width: 100%;
    overflow: hidden;

    img {
      width: 100%;
      border-radius: 15px;
    }

    // Style spécifique pour les tâches
    &.affaire-photo-tache-datc {
      img {
        height: 150px; // Hauteur moindre pour les photos de tâches
      }
    }

    // Style pour les photos d'affaires
    &.affaire-photo-affaire-datc {
      img {
        height: 200px;
      }
    }

    .img-mui-datc {
      font-size: 5rem;
      color: var(--c1);
    }
  }

  .affaire-details-datc {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    margin-top: 7px;
    h3, h4, p {
      text-align: center;
      margin: 5px 0;
    }

    button.btn-voir-affiare-datc {
      margin-top: 15px;
      width: auto;
      padding: 10px 15px;
      border-radius: 20px;
      background-color: var(--bg1);
      color: var(--c1);
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: var(--bg2);
      }
    }
  }
}
