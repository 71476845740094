.PlanningJour {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    width: 90%;
    max-width: 1200px;
  
    h2 {
      color: #2f4b52;
      text-align: center;
      margin-bottom: 20px;
    }
  
    p {
      text-align: center;
      color: #618587;
      font-size: 16px;
    }
  
    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 10px;
  
      thead {
        tr {
          background-color: #f3f6f8;
          th {
            padding: 12px 15px;
            text-align: left;
            font-weight: bold;
            color: #2f4b52;
            border-bottom: 3px solid #e1e4e8;
          }
        }
      }
  
      tbody {
        tr {
          &:nth-child(odd) {
            background-color: #f9fafb;
          }
  
          &:hover {
            background-color: #eef1f3;
          }
  
          td {
            padding: 10px 15px;
            border-bottom: 1px solid #e1e4e8;
            color: #617687;
            font-size: 14px;
  
            &:last-child {
              font-weight: bold;
              color: #2f4b52;
            }
          }
        }
      }
    }
  }
  