@import "../../../../App.scss";

.conatine-side-mess {
    display: flex;
    height: 100%;
    position: relative;

    .sidebar {
        height: 100%;
        width: 250px;
        background-color: #f4f4f4;
        transition: width 0.3s ease;

        .menu-button {
            position: absolute;
            top: 10px;
            left: 15px;
        }

        .sidebar-menu {
            list-style-type: none;
            padding-top: 60px;
            padding-left: 0px; // Pas de padding à gauche lorsque la barre est fermée

            li {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                cursor: pointer;

                svg {
                    font-size: 24px;
                    margin-right: 10px;
                }

                span {
                    display: block; 
                }
            }
        }

        &.closed {
            width: 70px;

            .sidebar-menu {
                li {
                    justify-content: center;

                    svg {
                        margin-right: 0; 
                    }

                    span {
                        display: none; 
                    }
                }
            }
        }

        &.open {
            .sidebar-menu {
                padding-left: 22px; // Ajout d'un padding à gauche lorsque la barre est ouverte
            }
        }
    }
}