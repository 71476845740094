@import "../../App.scss";

.card {
  height: 280px;
  width: 180px;
  background: linear-gradient(to right, var(--bg2), var(--bg1-light));
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  flex-wrap: nowrap;

  .containercard {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    flex-wrap: nowrap;
    margin: 0;

    .imgcard {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 125px;
      width: 125px;
      border-radius: 50%;
      margin-top: 10px;
      margin-bottom: 15px;
      .iconimgcard {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        margin-top: 10px;
        margin-bottom: 15px;
        border: solid 4px var(--c1);
        color: var(--c1);
      }
    }
    .info1 {
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;

      h3 {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* Limite à 2 lignes */
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        white-space: normal; /* Permet au texte de passer à la ligne suivante */
      }
    }

    .info2 {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: right;
      }
    }

    .btn-valid-card {
      button {
        height: 30px;
        width: 150px;
        margin-top: 10px;
        margin-bottom: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 20px;
        cursor: pointer;
        background-color: var(--bg1);
        color: var(--c1);
        font-weight: 200;
        letter-spacing: 1px;
        transition: background-color 0.3s ease;
      }

      button:hover {
        background-color: var(--bg2);
      }
    }
  }
}
.card.affaire-page {
  cursor: pointer;
}
