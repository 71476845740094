@import "../../../App.scss";

.listeManager-contain {

    display: flex;
    align-items: center;
    width: 100%; // Assurez-vous que le conteneur prend toute la largeur
    padding: 0 10px; // Ajoutez un padding pour ne pas déborder hors de la vue
    box-sizing: border-box;

    .MuiIconButton-root {
      margin-bottom: 12px; // Espacement entre les boutons
    }
  
    .bouton-overflow {
      display: flex;
      overflow-x: auto; // Active le défilement horizontal
      width: 100%; // Prend toute la largeur disponible
      gap: 10px; // Espacement entre les boutons
      padding: 10px 0; // Padding vertical pour le contenu défilant
      box-sizing: border-box;

      &::-webkit-scrollbar {
        height: 8px; // Hauteur de la barre de défilement
      }
  
      &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 10px;
      }
  
      button {
        flex-shrink: 0; // Empêche les boutons de rétrécir
        white-space: nowrap; // Empêche le texte du bouton de passer à la ligne
        padding: 8px 16px; // Espacement interne pour le bouton
        border: none;
        border-radius: 5px;
        background-color: var(--bg1);
        color: var(--c1);
        font-size: medium;
        cursor: pointer;
        transition: background-color 0.3s, transform 0.3s ease;

        &:hover {
          transform: scale(1.05); // Effet de zoom au survol
        }
      }
    }
}
